import { jsReportToHub, jsReportToKibala } from '@/store/action/asset';
import { getAppInfo } from '@/utils/bridge';

/*
const reqData = {
  reportPlat, //上报平台 0-全平台 1-Hubble 2-Kibala
  hubbleEventId // Hubble定义的EventId
  hubbleAttribute1, // Hubble定义的attribute1
  report_third_platform, // 是否上报第三方平台，如Google/facebook，取值为true/false，默认false
  hubbleExData: data, //其它参数(key-value对)
};
*/

const objectValue2Str = (o: any = {}) => {
  Object.keys(o).forEach((k) => {
    if (typeof o[k] === 'object') {
      return o[k] ? objectValue2Str(o[k]) : undefined;
    }
    o[k] = '' + o[k];
  });
  return o;
};
const fetchJsReportToApi = async (reqData: { [key: string]: any }) => {
  const appInfoRes = (await getAppInfo()) as any;
  const appInfo = appInfoRes.data;
  const { reportPlat = 0 } = reqData;
  const extObj = objectValue2Str(reqData?.hubbleExData);
  const params: any = {
    report_time: new Date().getTime(),
    event_class: 1,
    event_name: reqData.hubbleEventId,
    attributes: [reqData.hubbleAttribute1],
    ext_data: {
      tp_subchannelid: appInfo.SubChannel || '',
      tp_userid: String(appInfo.userId || 0),
      usertype: appInfo.userId > 0 ? '1' : '0',
      local_report_time: String(new Date().getTime()),
      phone_brand: appInfo.phone_brand,
      tp_plan: 'b',
      userid: String(appInfo.userId || 0),
      tp_deviceid: appInfo.tpDeviceID,
      country_code: appInfo.country_code,
      tp_channelid: appInfo.Channel,
      attribute1: reqData.hubbleAttribute1,
      tp_usertype: appInfo.userId > 0 ? '1' : '0',
      ...extObj,
    },
  };
  console.log(1111, appInfo, params);
  if (reportPlat === 1) {
    jsReportToHub({
      body: { list: [params] },
      callback: (is_ok: boolean, data?: number) => {
        if (is_ok) {
          console.log('hub report success');
        }
      },
    });
  } else if (reportPlat === 2) {
    jsReportToKibala({
      body: params,
      callback: (is_ok: boolean, data?: number) => {
        if (is_ok) {
          console.log('kibala report success');
        }
      },
    });
  } else {
    jsReportToHub({
      body: { list: [params] },
      callback: (is_ok: boolean, data?: number) => {
        if (is_ok) {
          console.log('hub report success');
        }
      },
    });
    jsReportToKibala({
      body: params,
      callback: (is_ok: boolean, data?: number) => {
        if (is_ok) {
          console.log('kibala report success');
        }
      },
    });
  }
};
export { fetchJsReportToApi };
