// @ts-nocheck
import HmacSHA1 from 'crypto-js/hmac-sha1';
import SparkMD5 from 'spark-md5';

/**
 * 海外签名请求算法
 * @namespace
 */
const xlauthlib: any = {};

/**
 * 网络请求回调函数定义 function(resp){}
 *
 * @static
 * @memberof xlauthlib
 *
 * @callback RequestCallback
 * @param {Object} resp - 响应数据
 * @param {boolean} resp.isSuccess - 是否成功
 * @param {number} resp.status - HTTP状态码
 * @param {string} resp.responseText - Response包体数据
 * @param {string} resp.errorMsg - 出错时的错误码
 */

/**
 * 网络请求回调函数定义 function(resp){}
 *
 * @static
 * @memberof xlauthlib
 *
 * @callback RequestCallback2
 * @param {Object} resp - 响应数据
 * @param {boolean} resp.isSuccess - 是否成功
 * @param {number} resp.status - HTTP状态码
 * @param {string} resp.responseText - Response包体数据
 * @param {Object} resp.response - responseText解析后的数据
 * @param {string} resp.errorMsg - 出错时的错误码
 */

/**
 * 计算签名：MD5
 */
function calc_sign_md5(data: any, a: any) {
  try {
    if (window['hex_md5']) {
      return window['hex_md5'](data);
    }
  } catch (error) {}
  return SparkMD5.hash(data);
}

// var digest_hmac_sha1 = null;

/**
 * 计算签名：Hmac-SHA1
 */
function calc_sign_hmac_sha1(data: any, secret: any) {
  // if (digest_hmac_sha1 == null) {
  //   if (window.CryptoJS) {
  //     digest_hmac_sha1 = window.CryptoJS.HmacSHA1;
  //   } else if (window.xl && window.xl.crypto) {
  //     digest_hmac_sha1 = window.xl.crypto.HmacSHA1;
  //   }
  // }
  const digest = HmacSHA1(data, secret);
  return digest.toString();
}

/**
 * 转为String类型,null和undefined返回为"";
 *
 * @static
 * @memberof xlauthlib
 *
 * @param {any} s
 * @returns {string}
 */
const stringNotNull = function (s: any): any {
  if (s === null || s === undefined) {
    return '';
  }
  return '' + s;
};

/**
 * 转为String类型, null和undefined返回为"", 其他类型则返回JSON.stringify的结果
 *
 * @static
 * @memberof xlauthlib
 *
 * @param {string|object} s
 * @returns {string}
 */
const stringifyNotNull = function (s: any) {
  var data = '';
  if (s === null || s === undefined) {
    data = '';
  } else if (Object.prototype.toString.call(s) === '[object String]') {
    data = s;
  } else {
    try {
      data = JSON.stringify(s);
    } catch (error) {
      data = '' + s;
    }
  }
  return data;
};

/**
 * @private
 * @param {string} s
 */
const splitQueryAndFragment = function (s: any) {
  var index = s.indexOf('?');
  var tail = s.indexOf('#');
  if (index == -1) {
    index = tail;
  } else {
    if (tail >= 0 && tail < index) {
      index = tail;
    }
  }
  if (index == -1) {
    return [s, '', ''];
  }
  if (s.charAt(index) == '#') {
    return [s.substr(0, index), '', s.substr(index)];
  }
  tail = s.indexOf('#', index);
  if (tail == -1) {
    return [s.substr(0, index), s.substr(index), ''];
  }
  return [s.substr(0, index), s.substr(index, tail - index), s.substr(tail)];
};

const packQueryString = function (params: any, encode = false) {
  if (Object.prototype.toString.call(params) === '[object String]') {
    return params;
  }
  var pairs = [];
  if (Object.prototype.toString.call(params) === '[object Object]') {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        var value = params[key];
        if (encode) {
          value = encodeURIComponent(value);
        }
        pairs.push('' + stringNotNull(key) + '=' + value);
      }
    }
  } else {
    for (let index = 0; index < params.length; index++) {
      const p = params[index];
      var value = stringNotNull(p[1]);
      if (encode) {
        value = encodeURIComponent(value);
      }
      pairs.push('' + stringNotNull(p[0]) + '=' + value);
    }
  }
  return pairs.join('&');
};

const appendQueryParams = function (s, params: any, encode = false) {
  var query = packQueryString(params, encode);
  if (query.length == 0) {
    return s;
  }
  var parts = splitQueryAndFragment(s);
  var q = parts[1];
  if (q.length == 0 || q === '?') {
    parts[1] = '?' + query;
  } else {
    parts[1] = q + '&' + query;
  }
  return parts.join('');
};

/**
 * @private
 * @param {string} s
 */
const getQueryString = function (s: any) {
  var index = s.indexOf('?');
  var tail = s.indexOf('#');
  if (index == -1) {
    index = tail;
  } else {
    if (tail >= 0 && tail < index) {
      index = tail;
    }
  }
  if (index == -1) {
    return '';
  }
  if (s.charAt(index) == '#') {
    return '';
  }
  tail = s.indexOf('#', index);
  if (tail == -1) {
    return s.substr(index);
  }
  return s.substr(index, tail - index);
};

/**
 * @private
 * @param {string} s
 */
const getQueryParameters = function (s: any, decode = true) {
  var query = getQueryString(s);
  if (query === '?' || query.length == 0) {
    return null;
  }
  if (query.charAt(0) === '?') {
    query = query.substr(1);
  }
  var params = [];
  var array = query.split('&');
  for (let i = 0; i < array.length; i++) {
    const p = array[i];
    var index = p.indexOf('=');
    var k = '',
      v = '';
    if (index == -1) {
      k = p;
    } else {
      k = p.substr(0, index);
      v = p.substr(index + 1);
    }
    if (decode) {
      v = decodeURIComponent(v);
    }
    params.push([stringNotNull(k), stringNotNull(v)]);
  }
  return params;
};

const signatureCompare = function (a: any, b: any) {
  var cmp = function (v1: any, v2: any) {
    if (v1 < v2) {
      return -1;
    } else if (v1 > v2) {
      return 1;
    }
    return 0;
  };
  var k1 = stringNotNull(a[0]);
  var k2 = stringNotNull(b[0]);
  if (k1 === k2) {
    var v1 = stringNotNull(a[1]);
    var v2 = stringNotNull(b[1]);
    return cmp(v1, v2);
  } else {
    return cmp(k1, k2);
  }
};

/**
 * Signature签名算法实现类
 *
 * @memberof xlauthlib
 */
class Signature {
  secret: any;
  digestFunc: any;
  /**
   * 签名算法实现类
   *
   * @param {string} secretKey 签名密钥
   */
  constructor(secretKey: any, algorithm: any) {
    this.secret = secretKey;
    this.digestFunc = calc_sign_md5;
    if (typeof algorithm === 'function') {
      this.digestFunc = algorithm;
    } else if (algorithm === 'md5') {
      this.digestFunc = calc_sign_md5;
    } else if (algorithm === 'hmac_sha1' || algorithm === 'hmac-sha1') {
      this.digestFunc = calc_sign_hmac_sha1;
    }
  }

  /**
   * 更新签名密钥
   *
   * @param {string} secretKey 签名密钥
   */
  setSecretKey(secretKey: any) {
    this.secret = secretKey;
  }

  digest(data: any, secretKey: any) {
    if (this.digestFunc != null) {
      return this.digestFunc(data, secretKey);
    }
    return calc_sign_md5(data, secretKey);
  }

  timestamp() {
    return new Date().getTime();
  }

  nonce() {
    return Math.floor(Math.random() * 2000000000) % 2000000000;
  }

  sign(params: any, secret: any) {
    params.sort(signatureCompare);
    var sb = [];
    for (let index = 0; index < params.length; index++) {
      const element = params[index];
      sb.push('' + stringNotNull(element[0]) + '=' + stringNotNull(element[1]));
    }
    sb.push(secret);
    return this.digest(sb.join(''), secret);
  }

  /**
   * 对URL签名
   *
   * @param {string} url 待签名的URL
   * @param {string} [method="GET"] 签名方法'GET' | 'POST'
   * @param {string} [body=""] 'POST'签名时要签名的HTTP的Body内容
   *
   * @returns {string} 签名后的URL
   */
  signUrl(url: any, method = 'GET', body = '') {
    var added = [
      ['nonce', this.nonce()],
      ['ts', this.timestamp()],
    ];

    var params = getQueryParameters(url) || [];

    if (method == 'POST' || method == 'PUT') {
      params = [];
      params = params.concat(added);
      params.push(['body', stringNotNull(body)]);
    } else {
      params = params.concat(added);
    }

    var sign = this.sign(params, this.secret);
    added.push(['sign', encodeURIComponent(sign)]);
    var u = appendQueryParams(url, added);
    return u;
  }
}

// const newXMLHttpRequest = function () {
//   try {
//     return cc.loader.getXMLHttpRequest();
//   } catch (error) {}
//   try {
//     return new XMLHttpRequest();
//   } catch (e) {}
// };

/**
 * 发送HTTP网络请求
 * @static
 * @memberof xlauthlib
 *
 * @param {xlauthlib.RequestCallback} requestCallback
 * @param {string} method "GET" | "POST"
 * @param {string} url
 * @param {string} [body = null]
 * @param {Object.<string,string>} [headers = null]
 */
// const sendHttpRequest = function (
//   requestCallback,
//   method,
//   url,
//   body = null,
//   headers = null
// ) {
//   var xhr = newXMLHttpRequest();
//   var noop = function () {};
//   var handler = requestCallback || noop;

//   if (xhr == null || xhr == undefined) {
//     handler({ isSuccess: false, errMsg: 'XMLHttpRequest is not supported' });
//     return;
//   }

//   xhr.timeout = 5000;
//   xhr.open(method, url, true);

//   if (headers) {
//     for (const key in headers) {
//       if (headers.hasOwnProperty(key)) {
//         const value = headers[key];
//         xhr.setRequestHeader(key, value);
//       }
//     }
//   }

//   xhr.onreadystatechange = function () {
//     if (xhr.readyState === 4) {
//       if (xhr.status >= 200 && xhr.status < 300) {
//         handler({
//           isSuccess: true,
//           status: xhr.status,
//           responseText: xhr.responseText,
//         });
//       } else {
//         handler({
//           isSuccess: false,
//           status: xhr.status,
//           responseText: xhr.responseText,
//         });
//       }
//     }
//   };

//   xhr.onerror = function (e) {
//     handler({ isSuccess: false, errMsg: 'onerror:' + e });
//   };

//   xhr.onabort = function () {
//     handler({ isSuccess: false, errMsg: 'onabort:' });
//   };

//   if (method === 'GET') {
//     xhr.send();
//   } else {
//     let data = stringifyNotNull(body);
//     xhr.send(data);
//   }
// };

xlauthlib.Signature = Signature;
xlauthlib.stringifyNotNull = stringifyNotNull;
xlauthlib.stringNotNull = stringNotNull;
// xlauthlib.sendHttpRequest = sendHttpRequest;
xlauthlib.appendQueryParams = appendQueryParams;
xlauthlib.getQueryString = getQueryString;

export default xlauthlib;
