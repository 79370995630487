class PostMsg {
  stacks: object = {};
  constructor() {
    window.onmessage = (e: any) => {
      const res = e.data;
      console.log('window.onmessage', res.type, res);
      if (res && res.type) {
        if (this.stacks[res.type]) {
          this.stacks[res.type](res.data);
        }
      }
    };
  }

  send(type: string, data?: any) {
    if (window.opener) {
      console.log('window.postMessage', type, data);
      window.opener.postMessage({ type, data }, '*');
    }
  }

  request(type: string, data?: any, cb?: any) {
    if (cb) {
      this.stacks[type] = cb;
    }
    this.send(type, data);
  }

  ready(cb: any) {
    if (window.opener) {
      this.request('xl_pay_loaded', null, (data: any) => {
        console.log('xl_pay_loaded get', data);
        cb(data);
        // this.send('xl_pay_info', { ret: 0, data: {} });
      });
    } else {
      cb(null);
    }
  }
}
const bus = new PostMsg();
export default bus;
