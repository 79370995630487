// import Home from '@/pages/home';
import Loadable from '@/utils/loadable';

const AssetInfo = Loadable('asset/info');
const AssetTransaction = Loadable('asset/transactions');
const WithdrawOption = Loadable('withdraw/option');
const WithdrawRecord = Loadable('withdraw/record');
const BindePaytm = Loadable('withdraw/paytm');
const RechargeAmount = Loadable('recharge/amount');
//const RechargeAmount = Loadable('recharge/notice');
const RechargeNotice = Loadable('recharge/notice');
const RechargeChannel = Loadable('recharge/channel');
const RechargeCard = Loadable('recharge/channel/recharge-way/recharge-card');
const RechargeScratchers = Loadable(
  'recharge/channel/recharge-way/recharge-scratchers'
);
const RechargeCommon = Loadable(
  'recharge/channel/recharge-way/recharge-common'
);
//意见反馈
const HelpFeedback = Loadable('help/feedback');
const HelpFeedbackHistory = Loadable('help/feedback/history');
const HelpFeedbackDetail = Loadable('help/feedback/detail');
const HelpPreview = Loadable('help/feedback/preview');

const DocumentProtocol = Loadable('document/privacy');
const DocumentAboutus = Loadable('document/aboutus');
const GpRechargeAmount = Loadable('gp/recharge/amount');

const UserTransfer = Loadable('agent-transfer/user-transfer');
const TransferRecord = Loadable('agent-transfer/transfers-record');
const AddAgentCash = Loadable('agent-transfer/add-agent-cash');
const AgentManage = Loadable('agent-transfer/agent-manage');

//币商
const PayOut = Loadable('pay/out');
const PayRoll = Loadable('pay/roll');
const PayTransCash = Loadable('pay/transcash');
const PayTransList = Loadable('pay/translist');
const PayCenterData = Loadable('pay/centerdata');
const PayCenterUser = Loadable('pay/centeruser');

// const NotFound = Loadable('404'); test-h5-app.teenpattiboss.com/asset/detail/01022020111910575530301420071881
export default [
  { path: '/asset/detail', component: AssetInfo, exact: true, needLogin: true },
  {
    path: '/asset/detail:order_param?/:order_id',
    component: AssetInfo,
    exact: true,
    needLogin: true,
  },
  {
    path: '/asset/transactions',
    component: AssetTransaction,
    exact: true,
    needLogin: true,
  },
  //{ path: '/withdraw/paytm', component: BindePaytm, exact: true },
  {
    path: '/withdraw/bind/:type',
    component: BindePaytm,
    exact: true,
    needLogin: true,
  },
  {
    path: '/withdraw/option',
    component: WithdrawOption,
    exact: true,
    needLogin: true,
  },
  {
    path: '/withdraw/record',
    component: WithdrawRecord,
    exact: true,
    needLogin: true,
  },
  {
    path: '/recharge/amount/:type?',
    component: RechargeAmount,
    exact: true,
    needLogin: true,
  },
  {
    path: '/recharge/channel/:amount/:giveAmount',
    component: RechargeChannel,
    exact: true,
    needLogin: true,
  },
  {
    path: '/recharge/card',
    component: RechargeCard,
    exact: true,
    needLogin: true,
  },
  {
    path: '/recharge/scratchers',
    component: RechargeScratchers,
    exact: true,
    needLogin: true,
  },
  {
    path: '/recharge/direct',
    component: RechargeCommon,
    exact: true,
    needLogin: true,
  },
  { path: '/recharge/notice', component: RechargeNotice, exact: true },
  {
    path: '/help/feedback',
    component: HelpFeedback,
    exact: true,
    needLogin: true,
  },
  {
    path: '/help/feedback/history',
    component: HelpFeedbackHistory,
    exact: true,
    needLogin: true,
  },
  {
    path: '/help/feedback/detail',
    component: HelpFeedbackDetail,
    exact: true,
    needLogin: true,
  },
  {
    path: '/help/feedback/preview',
    component: HelpPreview,
    exact: true,
    needLogin: true,
  },
  { path: '/document/privacy', component: DocumentProtocol, exact: true },
  { path: '/document/aboutus', component: DocumentAboutus, exact: true },
  {
    path: '/gp/recharge/amount/:type?',
    component: GpRechargeAmount,
    exact: true,
    needLogin: true,
  },
  {
    path: '/agent-transfer/user-transfer',
    component: UserTransfer,
    exact: true,
  },
  {
    path: '/agent-transfer/transfers-record',
    component: TransferRecord,
    exact: true,
  },
  {
    path: '/agent-transfer/add-agent-cash',
    component: AddAgentCash,
    exact: true,
  },
  {
    path: '/agent-transfer/agent-manage',
    component: AgentManage,
    exact: true,
  },
  {
    path: '/pay/out',
    component: PayOut,
    exact: true,
  },
  {
    path: '/pay/roll',
    component: PayRoll,
    exact: true,
  },
  {
    path: '/pay/transcash',
    component: PayTransCash,
    exact: true,
  },
  {
    path: '/pay/translist',
    component: PayTransList,
    exact: true,
  },
  {
    path: '/pay/centerdata',
    component: PayCenterData,
    exact: true,
  },
  {
    path: '/pay/centeruser',
    component: PayCenterUser,
    exact: true,
  },
];
