import axios, { ajax } from '@/helpers/axios';
import { setRequireParams, origin } from '@/utils/network';
import { getQueryVariable } from '@/utils/util';
const baseURL = '/api/user/v1';
export async function getCategories() {
  const params: any = {};
  params.user_id = localStorage.getItem('USER_ID')
    ? Number(localStorage.getItem('USER_ID'))
    : undefined;
  params.server_name = 'teenpatti';
  return axios(
    setRequireParams({
      method: 'POST',
      data: params,
      url: `${origin}${baseURL}/feedback/fbtype_list`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
export async function getQuestionsByCateFbType(params: any) {
  params.user_id = localStorage.getItem('USER_ID')
    ? Number(localStorage.getItem('USER_ID'))
    : undefined;
  params.server_name = 'teenpatti';
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/feedback/getfblistbytype`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
export async function sendFeedback(params: any) {
  params.user_id = localStorage.getItem('USER_ID')
    ? Number(localStorage.getItem('USER_ID'))
    : undefined;
  params.server_name = 'teenpatti';
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/feedback/submit`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Game-Version': (getQueryVariable('version') as string) || ''
      },
    })
  );
}
export async function getFeedbackList(params: any) {
  params.user_id = localStorage.getItem('USER_ID')
    ? Number(localStorage.getItem('USER_ID'))
    : undefined;
  params.server_name = 'teenpatti';
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/feedback/list_latest`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
export async function uploadImage(params: any) {
  params.user_id = localStorage.getItem('USER_ID')
    ? Number(localStorage.getItem('USER_ID'))
    : undefined;
  params.server_name = 'teenpatti';
  return ajax(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/feedback/uploadfeedbackfile`,
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8',
      },
    })
  );
}
