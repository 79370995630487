import globalReducer from './global';
import withdrawReducer, { withdrawAccountReducer } from './withdraw';
import assetReducer from './asset';
import helpReducer from './help';
import GpRechargeReducer from './gp_recharge';
export default {
  globalReducer,
  withdrawReducer,
  withdrawAccountReducer,
  assetReducer,
  helpReducer,
  GpRechargeReducer,
};
