import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Layouts from '../src/layouts';
import configureStore from '@/store';
import { IntlProvider } from 'react-intl';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { getAppInfo, refreshCookie } from '@/utils/bridge';
import { language, localeLang as intlLocaleLang } from '@/helpers/intl';
import AppLocale from '@/language';
import './utils/global';
import './global.less';

const App: React.FC = (): React.ReactElement => {
  const [lang, setLang] = React.useState(language);
  const [isGetAppInfo, setIsGetAppInfo] = React.useState(false);
  const [localeLang, setLocaleLang] = React.useState(intlLocaleLang);
  const [appInfoData, setAppInfoData] = React.useState(null);
  React.useEffect(() => {
    initApp();
  }, []);
  const initApp = () => {
    refreshCookie();
    getAppInfo()
      .then((appInfo: any) => {
        console.log('appInfo', JSON.stringify(appInfo, null, '\t'));
        if (
          appInfo &&
          appInfo.code === 0 &&
          appInfo.data &&
          appInfo.data.language
        ) {
          sessionStorage.setItem('USER_ID', appInfo.data.userId);
          sessionStorage.setItem('appInfo', JSON.stringify(appInfo.data));
          sessionStorage.setItem('language', appInfo.data.language);
          sessionStorage.setItem('flavor', appInfo.data.flavor || '');
          let lang = appInfo.data.language.split('-')[0];
          let langName = appInfo.data.language.toLowerCase();
          /** ADD_NEW_AREA */
          if (!['en', 'hi', 'id', 'bn', 'pt'].includes(lang)) {
            lang = 'en';
            sessionStorage.setItem('language', 'en-US');
          }
          /** ADD_NEW_AREA */
          if (!['en-US', 'hi-in', 'id-ID', 'bn', 'pt'].includes(langName)) {
            langName = 'en-us';
          }
          setLang(lang);
          setLocaleLang(langName);
          appInfo.data.lang = lang;
          appInfo.data.region =
            (navigator.userAgent.match(/\bregion\/(\w+)\b/) || [])[1] || 'in';

          if (location.hostname.indexOf('teenpatticoin') > -1) {
            // 新孟加拉
            appInfo.data.region = 'bn';
          }

          // appInfo.data.region = 'bn';
          /** ADD_NEW_AREA */
          const currency = {
            in: '₹',
            id: 'Rp',
            bd: '৳',
            br: 'R$',
            bn: '৳',
          }[appInfo.data.region];
          appInfo.data.currency = currency;

          sessionStorage.setItem('currency', currency);
          // 临时参数，支付是否跳转本地浏览器
          appInfo.data.isRechargeOpenBrowser = appInfo.data.region === 'in';
          setAppInfoData(appInfo.data);
        } else {
          console.log('获取系统信息失败');
        }
        setIsGetAppInfo(true);
      })
      .catch((err) => {
        console.error('getAppInfo failed', err);
      });
  };
  const currentAppLocale = AppLocale[lang];
  if (!isGetAppInfo && !/^\/document\//.test(location.pathname)) {
    return <div />;
  }

  return (
    <div className="app" data-lang={lang}>
      <IntlProvider locale={localeLang} messages={currentAppLocale.messages}>
        <BrowserRouter>
          <Provider store={configureStore().store}>
            <PersistGate loading={null} persistor={configureStore().persistor}>
              <Layouts appInfo={appInfoData} />
            </PersistGate>
          </Provider>
        </BrowserRouter>
      </IntlProvider>
    </div>
  );
};

export default App;
