import axios from '@/helpers/axios';
import { setRequireParams, origin } from '@/utils/network';
const baseURL = '/api/user/v1';
const baseURLV3 = '/api/user/v3';
export async function requestRecharge(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURLV3}/amt/recharge`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

export async function requestRechargeChannels(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/recharge/channel/list`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

export async function requestRechargeSdkCallback(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/recharge/sdkcallback`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

export async function modifyUserInfo(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURLV3}/account/modifyuserinfo`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

export async function getRechargeAct(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURLV3}/amt/rechargeact`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

export async function requestRechargeCard(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}/api/recharge_card/app/user_card/buy`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
export async function requestRechargeScratchers(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}/api/scratchers/app/recharge`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
export async function requestRechargeCommon(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURLV3}/amt/common_recharge`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}