import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { Message } from '@monir/h5';
import { createHttpRequest, signRequest, returnOpener } from '@/utils/bridge';
import { conmomPrams, getCookieInfo } from './config';
import { GLOBAL_RESULT_STATUS } from '@/constants/enums/global';
import { getCookie } from '@/utils/network';
import { randNumber } from '@/utils/data/data';
import { refreshCookie } from '@/utils/bridge';
import { getQueryVariable, isInSlots } from '@/utils/util';
import qs from 'qs';

export const baseUrl = conmomPrams.host;
let last_require_time = new Date().getTime();
let deferTimer = 0;
const getToken = () => {
  return `Bearer ${getCookie('token') || ''}`;
};
axios.defaults.baseURL = baseUrl;
axios.defaults.timeout = 20 * 1000;
axios.defaults.headers.common['Authorization'] = getToken();
// axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.defaults.transformRequest = [
  function (data, config) {
    config.Authorization = getToken();
    config['Accept-Language'] = sessionStorage.getItem('language') || '';
    if (!config['Content-Type']) {
      return qs.stringify(data);
    }
    switch (config['Content-Type'].toLowerCase()) {
      case 'application/json; charset=utf-8':
        return JSON.stringify(data);
      case 'multipart/form-data; charset=utf-8':
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        return formData;
      default:
        return qs.stringify(data);
    }
  },
];
const handleError = (error: any) => {
  return errorHandle(error.status, error.message);
};
const networkErrorMsg = 'System busy! Please try again later';
/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 * 50300
 */
const errorHandle = (status: number, other: any) => {
  // 状态码判断
  console.log('errorHandle', status, other);
  switch (status) {
    case 401:
      return Promise.resolve(
        new Error(
          JSON.stringify({
            code: -1,
            networkStatus: GLOBAL_RESULT_STATUS.ERROR,
            networkErrorMsg: networkErrorMsg, //'登录态失效，请重新登录',
          })
        )
      );
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      return Promise.resolve(
        new Error(
          JSON.stringify({
            code: -1,
            networkStatus: GLOBAL_RESULT_STATUS.ERROR,
            networkErrorMsg: networkErrorMsg, // '登录过期，请重新登录',
          })
        )
      );
    // 404请求不存在
    case 404:
      console.log('请求不存在');
      return Promise.resolve(
        new Error(
          JSON.stringify({
            code: -1,
            networkStatus: GLOBAL_RESULT_STATUS.ERROR,
            networkErrorMsg: networkErrorMsg, // '请求不存在',
          })
        )
      );
    default:
      if (status >= 500) {
        return Promise.resolve(
          new Error(
            JSON.stringify({
              code: -1,
              networkStatus: GLOBAL_RESULT_STATUS.ERROR,
              networkErrorMsg: networkErrorMsg, // '系统异常',
            })
          )
        );
      } else {
        return Promise.resolve(
          new Error(
            JSON.stringify({
              code: -1,
              networkStatus: GLOBAL_RESULT_STATUS.ERROR,
              networkErrorMsg: networkErrorMsg, // '系统异常',
            })
          )
        );
      }
  }
};

export async function request_flat(params: AxiosRequestConfig) {
  return request(params).then((res) => {
    if (res.code != 0) {
      Message.error(res.msg);
      throw Error(res.msg);
    }
    // 部分列表数据格式{items:[],total:0}
    if (res.data?.items) {
      res.data.list = res.data.items;
    }
    return res.data;
  });
}

async function request({
  url,
  data,
  method = 'post',
  ...rest
}: AxiosRequestConfig) {
  console.log(url, data);
  const request = createHttpRequest('jsSendAuthRequest', axios);
  //刷新cookies
  if (new Date().getTime() - last_require_time > 1000 * 10) {
    if (!deferTimer) {
      deferTimer = window.setTimeout(async () => {
        await refreshCookie();
        deferTimer = 0;
        clearTimeout(deferTimer);
      }, 3000);
    }
    last_require_time = new Date().getTime();
  }

  const cookieInfo = await getCookieInfo();
  const is_slots = isInSlots();
  return request({
    url,
    data: {
      origin: cookieInfo.origin,
      appversion: cookieInfo.appversion,
      from: 1,
      assets_type: is_slots ? 6 : undefined,
      flavor: sessionStorage.getItem('flavor') || 'gp_in',
      device_id: cookieInfo.device_id,
      nc: randNumber(8).toString(),
      ts: Math.floor(new Date().getTime() / 1000),
      ...data,
    },
    method,
    ...rest,
  })
    .then(function (response: AxiosResponse) {
      console.log(url, response);
      // handle success
      return response;
    })
    .then(function (response: AxiosResponse) {
      if (response.status === 200) {
        if (response.data) {
          switch (response.data.code) {
            case 50300:
              return Promise.reject();
              break;
            case 50315:
              returnOpener('xl_pay_error', response.data, (res: any) => {
                console.log('xl_pay_error return', res);
              });
              return response.data;
            default:
              return response.data;
          }
        } else {
          return response.data;
        }
      }
    })
    .catch(function (error: any) {
      if (
        error.request &&
        error.request.readyState === 4 &&
        error.request.status === 0
      ) {
        return Promise.resolve(
          new Error(
            JSON.stringify({
              code: -1,
              networkStatus: GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT,
              networkErrorMsg: '',
            })
          )
        );
      }

      const msg =
        error.response ||
        error.responseText ||
        `request failed, http status ${error.status}`;
      const err = handleError(msg);
      return err;
    });
}

const ajax = async ({
  url,
  data,
  method = 'post',
  ...rest
}: AxiosRequestConfig) => {
  const signInfo: any = await signRequest({ url: url }, 'GET');
  if (new Date().getTime() - last_require_time > 1000 * 10) {
    if (!deferTimer) {
      deferTimer = window.setTimeout(async () => {
        await refreshCookie();
        deferTimer = 0;
        clearTimeout(deferTimer);
      }, 3000);
    }
    last_require_time = new Date().getTime();
  }
  const cookieInfo = await getCookieInfo();
  return axios({
    url: signInfo.requestUrl,
    data: {
      origin: cookieInfo.origin,
      appversion: cookieInfo.appversion,
      from: 1,
      flavor: sessionStorage.getItem('flavor') || 'gp_in',
      device_id: cookieInfo.device_id,
      nc: randNumber(8).toString(),
      ts: Math.floor(new Date().getTime() / 1000),
      ...data,
    },
    method,
    ...rest,
    headers: Object.assign(
      rest.headers || {},
      (url || '').indexOf('etl.teenpattiboss.com') > -1 ? {} : signInfo.headers
    ),
  })
    .then(function (response: AxiosResponse) {
      // handle success
      return response;
    })
    .then(function (response: AxiosResponse) {
      if (response.status === 200) {
        if (response.data && response.data.code === 50300) {
          return Promise.reject();
        } else {
          return response.data;
        }
      }
    })
    .catch(function (error: any) {
      if (error.request.readyState === 4 && error.request.status === 0) {
        return Promise.resolve(
          new Error(
            JSON.stringify({
              code: -1,
              networkStatus: GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT,
              networkErrorMsg: '',
            })
          )
        );
      }
      return handleError(error.response);
    });
};

export default request;
export { ajax };
