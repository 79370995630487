import enlang from './entries/enLang';
import hilang from './entries/hiLang';
import bnlang from './entries/bnLang';
import ptlang from './entries/ptLang';

/** ADD_NEW_AREA */
const AppLocale = {
  en: enlang, // 英语
  hi: hilang, // 印度
  bn: bnlang, // 孟加拉
  pt: ptlang, // 巴西
};

export default AppLocale;
