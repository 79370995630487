import { FETCH_PRODUCT_LIST } from '@/constants/actionTypes';
import { GpProductList } from '@/models';

const initialState: GpProductList = {
  items: [],
};

const GpRechargeReducer = (
  state: GpProductList = initialState,
  action: any
): GpProductList => {
  switch (action.type) {
    case FETCH_PRODUCT_LIST:
      return {
        ...state,
        items: action.payload.items,
      };
    default:
      return state;
  }
};

export default GpRechargeReducer;
