const SAVE_ASSET_INFO = 'SAVE_ASSET_INFO';
const SAVE_RECHARGELIST_INFO = 'SAVE_RECHARGELIST_INFO';
const SAVE_ASSET_TRANSAcTIONS = 'SAVE_ASSET_TRANSAcTIONS';
const RESET_TRANSAcTION_LIST = 'RESET_TRANSAcTION_LIST';
const SAVE_RECHARGE_AGENT_LIST = 'SAVE_RECHARGE_AGENT_LIST';
const SAVE_RECHARGE_CONF = 'SAVE_RECHARGE_CONF';
export {
  SAVE_ASSET_INFO,
  SAVE_ASSET_TRANSAcTIONS,
  RESET_TRANSAcTION_LIST,
  SAVE_RECHARGELIST_INFO,
  SAVE_RECHARGE_AGENT_LIST,
  SAVE_RECHARGE_CONF,
};
