import { multiToOne } from '@/utils/object';

import common from '../locales/pt/common.json';
import asset from '../locales/pt/asset.json';
import withdraw from '../locales/pt/withdraw.json';
import recharge from '../locales/pt/recharge.json';
import gpRecharge from '../locales/pt/gp_recharge.json';
import help from '../locales/pt/help.json';
import component from '../locales/pt/component.json';
import agentTransfer from '../locales/pt/agent-transfer.json';
import pay from '../locales/en/pay.json';

const obj = {
  page: {
    common,
    component,
    withdraw,
    recharge,
    gpRecharge,
    help,
    asset,
    agentTransfer,
    pay,
  },
};

export type PtLangType = NestedKeyOf<typeof obj>;

export default { messages: multiToOne(obj), locale: 'pt' };
