import { SAVE_GLOBAL_USERINFO } from '@/constants/actionTypes';
import { GlobalState } from '@/models';

const initialState: GlobalState = {
  userInfo: {},
};

const GlobalReducer = (
  state: GlobalState = initialState,
  action: any
): GlobalState => {
  switch (action.type) {
    case SAVE_GLOBAL_USERINFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
};

export default GlobalReducer;
