import { compare } from 'compare-versions';
import UAParser from 'ua-parser-js';

export const regs = {
  number: /([0-9]*)/g,
  number_p: /^([0-9]*)$/,
  is_number: /^\d+$/,
  two_float: /^(([0-9]*)|([0-9]*\.\d{0,2}))$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  //email: /^\S+@[^\s@]+\.[^\s@\.]+$/,
  email_id: /^[a-zA-Z0-9_\-+\.!\&]+@(?:[a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,63}$/,
  phone: /^\d{10}$/,
  phone_in: /^[6-9]\d{9}$/,
  phone_id: /^(0|62)?\d{8,12}$/, //印尼手机号码格式
  phone_br: /^\+55\d{11}$/, //巴西手机号码格式
  ifsc: /^[A-Za-z0-9]{4}0[a-zA-Z0-9]{6}$/,
  upi: /^[\w\.\-]+@[\w\.]+$/,
  bank: /^[a-zA-Z0-9]{9,18}$/,
  gopayid: /^0\d{8,12}$/,
  bank_no_in: /^\d{6,}$/,
  bank_name_in: /^[a-zA-Z][a-zA-Z\s]*[a-zA-Z]$/,
  bank_ifsc_in: /^[A-Z]{4}0[[A-Z0-9]{6}$/,
  bank_phone_in: /^[6-9]\d{9}$/,
};

export function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
export const isInApp = () => {
  return /teenpatti/i.test(window.navigator.userAgent.toLowerCase());
};

export const substandardStr = [
  'sina.com',
  'sohu.com',
  'qq.com',
  'vip.qq.com',
  '163.com',
  '139.com',
  'mail.aliyun.com',
  'mail.sohu.com',
  'mail.sina.com.cn',
];

export const formatWhatsAppText = (data: Record<string, string | number>) => {
  return Object.entries(data)
    .map(([k, v]) => `${k}: ${v}`)
    .join('\n');
};
export const isHighVersionFromUA = () => {
  const CustomBrowsersList2 = [
    [/(VersionName|Thunder|XLGame)\/([\w.]+)/i],
    [UAParser.BROWSER.NAME, UAParser.BROWSER.VERSION],
  ];
  const parser = new UAParser({ browser: CustomBrowsersList2 });
  const result: any = parser.getResult();
  console.log(1112, result);
  if (result?.browser && result?.browser?.version) {
    const ret = compare(result?.browser?.version, '4.26.0.0', '>=');
    return ret;
  }
  const strArr = window.navigator.userAgent.toLowerCase().split('teenpatti/');
  const res = strArr?.[1]?.split(' ')?.[0];
  return res ? Number(res) >= 4260000 : false;
};

/** 判断是否在slots游戏中 */
export const isInSlots = () => {
  return getQueryVariable('slots') === '1';
};

export const hasValue = (obj?: Record<string, any>): boolean => {
  return !!obj && !!Object.values(obj).filter(Boolean).length;
};
