import { getCookie } from '@/utils/network';
import getInfo from '@/utils/browser';
import { isHasBridgeMethod } from '@/utils/bridge';
// const getHost = (env: string) => {
//   console.log(env);
//   let host = '';
//   switch (env) {
//     case 'mock':
//       host = '';
//       break;
//     case 'dev':
//       host = 'api-dev.teenpattiboss.com';
//       break;
//     case 'test':
//       host = 'api-test.teenpattiboss.com';
//       break;
//     default:
//       host = 'api.teenpattiboss.com';
//   }
//   return host;
// };

/**
 * 请求的公共参数
 */
export const conmomPrams: any = {
  // host: getHost(process.env.TEENPATTI_ENV as string)
  host: '',
};
export async function getCookieInfo() {
  /** 4.22.0新版本客户端，直接由前端向服务端发送请求, 取消cookie传值 */
  if (!isHasBridgeMethod('jsSendAuthRequest')) {
    try {
      const appInfoString = sessionStorage.getItem('appInfo');
      if (appInfoString) {
        const appInfo = JSON.parse(appInfoString);
        return {
          origin: String(appInfo.origin),
          appversion: appInfo.version,
          device_id: appInfo.tpDeviceID,
        };
      }
    } catch (e) {
      console.error(e);
    }
  }
  const { isFromWebBrowser, APP_INFO } = getInfo();
  const origin = isFromWebBrowser ? APP_INFO.origin : getCookie('origin');
  const appversion = isFromWebBrowser
    ? APP_INFO.version
    : getCookie('appversion');
  const device_id = isFromWebBrowser
    ? APP_INFO.device_id
    : getCookie('device_id');
  return {
    origin,
    appversion,
    device_id,
  };
}
