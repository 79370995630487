import {
  SAVE_HELP_CATEGORIES,
  SAVE_HELP_TEMPVALUES,
  SAVE_HELP_QUESTIONS,
  SAVE_HELP_CATEGORIEQUESTIONS,
  SAVE_HELP_QUESTIONDETAIL,
  SAVE_HELP_FEEDBACKLIST,
  SAVE_HELP_FEEDBACKDETAIL,
  CLEAR_HELP_TEMPVALUES,
  DELETE_HELP_PICTURES,
  SAVE_HELP_COMMON_FB,
} from '@/constants/actionTypes';
import { HelpState } from '@/models';

const initialState: HelpState = {
  categories: [],
  messages: [],
  categoryQuestions: [],
  questions: [],
  questionDetail: {},
  feedbackList: [],
  feedbackDetail: {},
  tempValues: {
    pictures: [],
  },
};

const HelpReducer = (
  state: HelpState = initialState,
  action: any
): HelpState => {
  switch (action.type) {
    case SAVE_HELP_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SAVE_HELP_COMMON_FB:
      return {
        ...state,
        messages: action.payload,
      };
    case SAVE_HELP_TEMPVALUES:
      return {
        ...state,
        tempValues: {
          ...action.payload,
        },
      };
    case SAVE_HELP_QUESTIONS:
      return {
        ...state,
        questions: [...action.payload],
      };
    case SAVE_HELP_CATEGORIEQUESTIONS:
      return {
        ...state,
        categoryQuestions: action.payload.messages,
      };
    case SAVE_HELP_QUESTIONDETAIL:
      return {
        ...state,
        questionDetail: { ...action.payload },
      };
    case SAVE_HELP_FEEDBACKLIST:
      return {
        ...state,
        feedbackList: action.payload,
      };
    case SAVE_HELP_FEEDBACKDETAIL:
      return {
        ...state,
        feedbackDetail: { ...action.payload },
      };
    case CLEAR_HELP_TEMPVALUES:
      return {
        ...state,
        tempValues: {
          pictures: [],
        },
      };
    case DELETE_HELP_PICTURES:
      return {
        ...state,
        ...Object.assign(state.tempValues, {
          pictures: action.payload,
        }),
      };
    default:
      return state;
  }
};

export default HelpReducer;
