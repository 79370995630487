import axios from '@/helpers/axios';
import { setRequireParams, origin } from '@/utils/network';
const baseURL = '/api/user/pay-center/v1';
// /api/user/pay-center/v1/buy/gp_pay/product/list
export async function getProductList(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/buy/gp_pay/product/list`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
