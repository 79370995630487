import axios from '@/helpers/axios';
import { setRequireParams, origin } from '@/utils/network';
import { getSignBody } from '@/utils/bridge';
const baseURL = `/api/user/v1`;
export async function requestWithdraw(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}/web-server${baseURL}/amt/withdraw`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

export async function getWithdrawAmountSet(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/list_withdraw_set`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

export async function requestBindPaytm(params: any) {
  console.log('requestBindPaytm', params);
  const response: any = await getSignBody(JSON.stringify(params));
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/withdraw/bindpaytm`,
      data: {
        body: response.data.signStr || '',
      },
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

export async function requestBindAccount(params: any) {
  //const response: any = await getSignBody(JSON.stringify(params));
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/withdraw/bind_account`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

export async function requestBindAccountWithPanCard(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/withdraw/bind_upi_with_pancard`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 查询审核中的提现订单
 * @param params
 */
export async function getWithdrawWaitHistoryist(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}/web-server${baseURL}/amt/withdraw/list/wait_audit`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 取消提现
 * @param params
 */
export async function setWithdrawCancel(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}/web-server${baseURL}/amt/withdraw/cancel`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 获取绑定列表
 * @param params
 */
export async function getBindList() {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/withdraw/bind/list`,
      // data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 绑定提现帐号
 * @param params
 */
export async function bindAccount(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}/web-server${baseURL}/api/user/v1/amt/withdraw/bind_account`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 更新UPI信息
 * @param params
 */
 export async function updateUPIAccount(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/withdraw/update_upi_account`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 获取UPI信息
 */
 export async function getUPIAccount() {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/withdraw/get_upi_account`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 更新Bank信息
 * @param params
 */
 export async function updateBankAccount(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/withdraw/update_bank_account`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 获取Bank信息
 */
 export async function getBankAccount() {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/withdraw/get_bank_account`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 更新提现信息
 * @param params
 */
 export async function updateWithdrawAccount(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/withdraw/bind/universal/update`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 获取提现信息
 */
export async function getWithdrawAccount(params: { account_type: number }) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/withdraw/bind/universal/get`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 获取Pan Card信息
 * @param params
 */
 export async function getPanCardInfo(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/withdraw/ocr_pancard`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
