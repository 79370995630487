import * as React from 'react';
// import {  LocaleProvider } from "antd-mobile";

import Routes from '../routers';
// import { useSelector } from 'react-redux';
// import { RouteComponentProps } from 'react-router';
// import { withRouter } from 'react-router-dom';

interface Props {
  appInfo: any;
}

const BasicLayout: React.FC<Props> = (props: Props): React.ReactElement => {
  return <Routes appInfo={props.appInfo} />;
};
export default BasicLayout;
