import { multiToOne } from '@/utils/object';

import common from '../locales/hi/common.json';
import asset from '../locales/hi/asset.json';
import withdraw from '../locales/hi/withdraw.json';
import recharge from '../locales/hi/recharge.json';
import gpRecharge from '../locales/hi/gp_recharge.json';
import help from '../locales/hi/help.json';
import component from '../locales/hi/component.json';
import agentTransfer from '../locales/hi/agent-transfer.json';
import pay from '../locales/hi/pay.json';

const obj = {
  page: {
    common,
    component,
    withdraw,
    recharge,
    gpRecharge,
    help,
    asset,
    agentTransfer,
    pay,
  },
};

export type HiLangType = NestedKeyOf<typeof obj>;

export default { messages: multiToOne(obj), locale: 'hi-IN' };
