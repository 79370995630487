const SAVE_WITHDRAW_LIST = 'SAVE_WITHDRAW_LIST';
const RESET_WITHDRAW_LIST = 'RESET_WITHDRAW_LIST';
const SVAE_WITHDRAW_AMOUNT_LIST = 'SVAE_WITHDRAW_AMOUNT_LIST';
const SAVE_WITHDRAW_WAIT_AUDIT_LIST = 'SAVE_WITHDRAW_WAIT_AUDIT_LIST';
const GET_BIND_LIST = 'GET_BIND_LIST';
const BIND_ACCOUNT = 'BIND_ACCOUNT';
export {
  SAVE_WITHDRAW_LIST,
  RESET_WITHDRAW_LIST,
  SVAE_WITHDRAW_AMOUNT_LIST,
  SAVE_WITHDRAW_WAIT_AUDIT_LIST,
  GET_BIND_LIST,
  BIND_ACCOUNT,
};
