import { useIntl } from 'react-intl';
import { useMemoizedFn } from 'ahooks';
import { EnLangType } from '../../language/entries/enLang';

export const language = 'en';
export const localeLang = 'en-US';

export type LangId = EnLangType;

export type FormatMessage = (
  id: LangId,
  values?: Record<string, any>
) => string;

export const useFormatMessage = () => {
  const intl = useIntl();

  const formatMessageFn = useMemoizedFn<FormatMessage>((id, values) => {
    return intl.formatMessage({ id }, values);
  });

  return formatMessageFn;
};
