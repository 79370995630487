import './jsBridge';
import dsBridge from 'dsbridge';
import { pick } from 'lodash';
import { mergeUrlQuery } from '@monir/utils';
import isUrl from 'is-url';
import qString from 'query-string';
import bus from '@/utils/postmsg';
import getInfo from '@/utils/browser';
import xlauthlib from '@/utils/xlauthlib';
import { fetchJsReportToApi } from '@/utils/js-report';
import { isHighVersionFromUA } from '@/utils/util';

declare global {
  interface Window {
    WebJsBridge: {
      call(funname: string, funData?: any, fun?: (retValue: any) => void): any;
      hasNativeMethod: (name: string) => boolean;
      register: (name: string, handler?: Function) => void;
      registerAsyn: undefined;
    };
    onNativeCallback: (name: string, id: string, value?: any) => void;
  }
}

const Native: any = isHighVersionFromUA() ? window.WebJsBridge : dsBridge;

const isHasProductMethod = (name: string) => {
  // return /*process.env.NODE_ENV === 'production' && */ dsBridge.hasNativeMethod(
  //   name
  // );
  return Native.hasNativeMethod(name);
};

export const isHasBridgeMethod = (name: string) => {
  // return dsBridge.hasNativeMethod(name);
  return Native.hasNativeMethod(name);
};

export interface IEventData {
  action: number | string;
  posts?: any;
}
export interface ICallbackData {
  code: number;
  data: any;
  message: string;
}
export interface IWebviewProps {
  url: string;
  title?: string;
  screentype?: 0 | 1;
}
// 事件上报
export function sendEvent(data: any) {
  return new Promise((resolve) => {
    // dsBridge.call('jsSendEvent', data, resolve);
    if (Native.hasNativeMethod('jsSendEvent')) {
      Native.call('jsSendEvent', data, resolve);
    } else {
      console.log('JsBridge暂不支持的jsSendEvent事件上报', data);
    }
  });
}
// 事件上报
export function eventStat(data: IEventData) {
  const responst = sendEvent({
    action: data.action,
    posts: data.posts || '',
  });
  0 && console.log('事件上报', responst);
}
// app右上角按钮
export function setRightButton(data: any) {
  return new Promise((resolve) => {
    // dsBridge.call('jsSetRightButton', data, resolve);
    if (Native.hasNativeMethod('jsSetRightButton')) {
      Native.call('jsSetRightButton', data, resolve);
    } else {
      console.log('JsBridge暂不支持的jsSetRightButton事件', data);
    }
  });
}
// 点击app右上角按钮
export function rightButtonClick(callback: Function) {
  // dsBridge.register('onRightButtonClick', callback);
  Native.register('onRightButtonClick', callback);
}

//原生日志上传
export function reportLog(callback: Function) {
  return new Promise((resolve) => {
    // if (dsBridge.hasNativeMethod('jsReportLog')) {
    //   dsBridge.call('jsReportLog', callback);
    // }
    if (Native.hasNativeMethod('jsReportLog')) {
      Native.call('jsReportLog', callback);
    } else {
      callback({
        code: 0,
        data: { path: '' },
      });
    }
  });
}
function queryString(url?: string) {
  url = url || window.location.search;
  // 移除 #
  if (url.indexOf('#') > -1) {
    url = url.split('#')[0];
  }
  const ret = {};
  const dividerIndex = url.indexOf('?');
  if (dividerIndex === -1) {
    return ret;
  }
  const query = url.slice(dividerIndex + 1);
  const vars = query.split('&');
  const len = vars.length;

  for (let i = 0; i < len; i++) {
    const pair = vars[i].split('=');

    const key = pair[0];
    const val = decodeURIComponent(pair[1]);

    if (typeof ret[key] === 'undefined') {
      // 如果值没有
      ret[key] = val;
    } else if (typeof ret[key] === 'string') {
      // 如果值存在了 a=123&a=345 => a: ['123', '345']
      const arr = [ret[key], val];
      ret[key] = arr;
    } else {
      // 如果值已经是数组对象了
      ret[key].push(val);
    }
  }

  return ret;
}
const urlQuery = queryString() as any;
// 获取origin, version
export function getAppInfo() {
  if (isHasProductMethod('jsGetAppInfo')) {
    return new Promise((resolve, reject) => {
      try {
        Native.call('jsGetAppInfo', {}, (resp: any) => {
          console.log('jsGetAppInfo:*************************');
          console.log(resp);
          if (!isHighVersionFromUA()) {
            resolve(resp);
          } else {
            resolve({
              code: 0,
              message: 'ok',
              data: resp,
            });
          }
        });
      } catch (e) {
        reject(e);
      }
    });
    // return createBridgePromise('jsGetAppInfo', {});
  } else {
    return new Promise((resolve, reject) => {
      const { mock_uid, mock_flavor } = urlQuery;
      resolve({
        code: 0, //[0: 默认成功],
        message: 'ok',
        data: {
          "origin": 1,
          "version": "4.46.0",
          "language": "en",
          "flavor": "bld_base",
          "versionCode": 4460000,
          "appVersion": "4.46.0",
          "systemVersion": "13",
          "productID": "65",
          "partnerID": "bld_base",
          "deviceID": "d5ed81929805945155901c1d1065c114",
          "tpDeviceID": "HW_00000000-5E4C-8274-0000-00001B6C1586",
          "Channel": "bld_base",
          "Device-Language": "en",
          "app_name": "Teenpatti Cash",
          "phone_brand": "Xiaomi",
          "country_code": "CN",
          "login_token": "ddb785b8-fe81-4088-9a0a-3f4def57c470",
          "userId": 49966713,
          "signature_key": "a5edab44f714f290908af2551e3116f0",
          "Package-Name": "com.fdsgv.safcxcv.ferg",
          "App-Type": "android",
          "Account-Id": "22005",
          "Mobile-Type": "android",
          "Platform-Version": "13",
          "Phone-Model": "M2011K2C",
          "Client-Ip": "172.18.0.186",
          "IMEI": "",
          "Adjust-GoogleAdId": "43b66bfc-ed36-4fe8-a1a2-da366edca0a7"
        },
      });
    });
  }
}

export function createBridgePromise(name: string, params: any) {
  return new Promise((resolve, reject) => {
    try {
      if (Native.hasNativeMethod(name)) {
        console.log('enter', name);
        Native.call(name, params, resolve);
        // } else if (dsBridge.hasNativeMethod(name)) {
        //   console.log('enter', name);
        //   dsBridge.call(name, params, resolve);
      } else if (params && params.url) {
        window.location.href = params.url;
      }
    } catch (e) {
      reject(e);
    }
  });
}

export function getGooglePlayProducts(products: string[]) {
  const data = {
    skus: products.map((product_id: string) => {
      return {
        sku: product_id,
      };
    }),
  };
  if (Native.hasNativeMethod('jsGetPrice')) {
    // if (dsBridge.hasNativeMethod('jsGetPrice')) {
    return createBridgePromise('jsGetPrice', data);
  }
  return Promise.resolve({
    code: 0,
    msg: '',
    data: {
      products: [
        { sku: 'chips', price: '₹65.00' },
        { sku: 'chips2', price: '₹300.00' },
        { sku: 'chips4', price: '₹650.00' },
        { sku: 'chips_3', price: '₹110.00' },
      ],
    },
  });
}

export function payForProduct(product_id: string) {
  return createBridgePromise('jsPayForProduct', { sku: product_id });
}

export function updatePayInfo() {
  return createBridgePromise('jsUpdatePay', {});
}

export function signRequest(params: any, _method?: string) {
  const action = 'jsSignAuthRequest';
  const method = (_method || params.method || 'GET').toUpperCase();
  // if (dsBridge.hasNativeMethod(action)) {
  if (Native.hasNativeMethod(action)) {
    return new Promise((resolve, reject) => {
      try {
        // const res = dsBridge.call(action, {
        const res = Native.call(action, {
          url: params.url, //需要签名的URL
          signMethod: method,
          //签名方式，"GET" - 对Query参数进行签名； "POST" - 对application/json body进行签名
          body: method === 'GET' ? undefined : JSON.stringify(params.data), //POST签名时需要的Body，需要转为字符串如JSON.stringify
        });
        //delete res.headers['User-Id'];
        if (res && res.headers) {
          res.headers = {
            'User-Id': res.headers['User-Id'],
            'Device-Id': res.headers['Device-Id'],
            'Product-Id': res.headers['Product-Id'],
            'X-Auth-Token': res.headers['X-Auth-Token'],
          };
        }

        resolve(res);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  } else {
    /** 4.22.0新版本客户端，直接由前端向服务端发送请求 */
    try {
      const appInfoString = sessionStorage.getItem('appInfo');
      if (appInfoString) {
        const appInfo = JSON.parse(appInfoString);
        const sign = new xlauthlib.Signature(appInfo.signature_key, 'md5');
        const headers = {};
        headers['Device-Id'] = appInfo.tpDeviceID;
        headers['Device-Id-TP'] = appInfo.tpDeviceID;
        headers['Device-Id-Hubble'] = appInfo.deviceID;
        headers['User-Id'] = appInfo.userId;
        headers['Session-Id'] = appInfo.login_token;
        headers['Country-Code'] = appInfo.country_code;
        headers['Device-Language'] = appInfo.language;
        headers['Accept-Language'] = appInfo.language;
        if (appInfo.SubChannel) {
          headers['SubChannel'] = appInfo.SubChannel;
        }
        headers['Product-Id'] = appInfo.productID;
        headers['Channel'] = appInfo.partnerID;
        headers['Version-Code'] = appInfo.versionCode;
        headers['Version-Name'] = appInfo.appVersion;
        headers['X-Auth-Token'] = appInfo.login_token;
        headers['APP-TYPE'] = appInfo['App-Type'];
        headers['Phone-Model'] = appInfo['Phone-Model'];
        headers['Mobile-Type'] = appInfo['Mobile-Type'];
        headers['Account-Id'] = appInfo['Account-Id'];
        headers['Client-Ip'] = appInfo['Client-Ip'];
        headers['Adjust-Id'] = appInfo['Adjust-Id'];
        headers['Adjust-GoogleAdId'] = appInfo['Adjust-GoogleAdId'];
        headers['Platform-Version'] = appInfo['Platform-Version'];
        headers['Package-Name'] = appInfo['Package-Name'];
        headers['IMEI'] = appInfo.IMEI;
        params.url = sign.signUrl(
          params.url,
          params.method,
          JSON.stringify(params.data)
        );
        return Promise.resolve({
          url: params.url,
          requestUrl: params.url,
          headers,
        });
      }
    } catch (e) {
      console.error(e);
    }
    return Promise.resolve({
      url: params.url,
      requestUrl: params.url,
      headers: {},
    });
  }
}

export function createHttpRequest(name: string, callRequest: any) {
  // if (dsBridge.hasNativeMethod(name)) {
  if (Native.hasNativeMethod(name)) {
    return (params: any) => {
      return new Promise((resolve, reject) => {
        try {
          if (params.headers) {
            delete params.headers['Content-Type'];
          }

          if (/\/amt\/assetinfo/.test(params.url)) {
            params.url = params.url.replace('/v1/', '/v3/');
          }
          if (/^\/\//.test(params.url)) {
            params.url = `${location.protocol}${params.url}`;
          }
          // dsBridge.call(
          Native.call(
            name,
            {
              url: params.url,
              method: params.method,
              header: {
                ...params.headers,

                //Cookie: document.cookie,
              },
              withWebCookie: true,
              postContent: JSON.stringify(params.data),
            },
            (res: any) => {
              if (res && res.code === 0) {
                const data = res && res.data;
                if (data) {
                  try {
                    data.data = JSON.parse(data.responseText);
                  } catch (err) {
                    console.error('parse data to json failed', err, data);
                    reject(data && data.responseText);
                    return;
                  }
                }
                resolve(data);
              } else if (res.code === -1) {
                reject({
                  request: {
                    readyState: 4,
                    status: res.data.status,
                  },
                  response: { ...res.data, message: res.data.responseText },
                });
              } else {
                console.error(
                  `client reponse failed: request=>${JSON.stringify(
                    params
                  )} response=>`,
                  res
                );
                reject((res && res.data) || res.message);
              }
            }
          );
        } catch (err) {
          reject(err);
        }
      });
    };
  } else {
    /** 新版本客户端，直接由前端向服务端发送请求 */
    try {
      const appInfoString = sessionStorage.getItem('appInfo');
      if (appInfoString) {
        const appInfo = JSON.parse(appInfoString);
        const sign = new xlauthlib.Signature(appInfo.signature_key, 'md5');
        return (params: any) => {
          if (/\/amt\/assetinfo/.test(params.url)) {
            params.url = params.url.replace('/v1/', '/v3/');
          }
          params.headers['Device-Id'] = appInfo.tpDeviceID;
          params.headers['Device-Id-TP'] = appInfo.tpDeviceID;
          params.headers['Device-Id-Hubble'] = appInfo.deviceID;
          params.headers['User-Id'] = appInfo.userId;
          params.headers['Session-Id'] = appInfo.login_token;
          params.headers['Country-Code'] = appInfo.country_code;
          params.headers['Device-Language'] = appInfo.language;
          params.headers['Accept-Language'] = appInfo.language;
          if (appInfo.SubChannel) {
            params.headers['SubChannel'] = appInfo.SubChannel;
          }
          params.headers['Product-Id'] = appInfo.productID;
          params.headers['Channel'] = appInfo.partnerID;
          params.headers['Version-Code'] = appInfo.versionCode;
          params.headers['Version-Name'] = appInfo.appVersion;
          params.headers['X-Auth-Token'] = appInfo.login_token;
          params.headers['APP-TYPE'] = appInfo['App-Type'];
          params.headers['Phone-Model'] = appInfo['Phone-Model'];
          params.headers['Mobile-Type'] = appInfo['Mobile-Type'];
          params.headers['Account-Id'] = appInfo['Account-Id'];
          params.headers['Client-Ip'] = appInfo['Client-Ip'];
          params.headers['Adjust-Id'] = appInfo['Adjust-Id'];
          params.headers['Adjust-GoogleAdId'] = appInfo['Adjust-GoogleAdId'];
          params.headers['Platform-Version'] = appInfo['Platform-Version'];
          params.headers['Package-Name'] = appInfo['Package-Name'];
          params.headers['IMEI'] = appInfo.IMEI;
          params.url = sign.signUrl(
            params.url,
            params.method,
            JSON.stringify(params.data)
          );
          callRequest.interceptors.response.use(function (response: any) {
            if ([50301, 50307, 50315, 50300].includes(response.data.code)) {
              closeAllPage();
            }
            return response;
          });
          return callRequest(params);
        };
      }
    } catch (e) {
      console.error(e);
    }
    /** 使用web打开页面 */
    const { isFromWebBrowser, APP_INFO, USER_INFO, AUTH_INFO } = getInfo();
    if (isFromWebBrowser) {
      const sign = new xlauthlib.Signature(
        AUTH_INFO.signature_key,
        // 'hmac-sha1'
        'md5'
      );
      return (params: any) => {
        if (/\/amt\/assetinfo/.test(params.url)) {
          params.url = params.url.replace('/v1/', '/v3/');
        }
        params.headers['Channel'] = APP_INFO.flavor;
        params.headers['Product-Id'] = APP_INFO.productID;
        //params.headers['Content-Type'] = 'application/json';
        params.headers['User-Id'] = USER_INFO.userId;
        params.headers['Version-Name'] = APP_INFO.version;
        params.headers['Version-Code'] = APP_INFO.version_code;

        params.headers['Device-Id'] =
          APP_INFO.tpDeviceID || APP_INFO.deviceID || APP_INFO.device_id;
        params.headers['X-Auth-Token'] = AUTH_INFO.auth;
        // params.headers['Content-Type'] = 'application/json; charset=UTF-8';
        params.url = sign.signUrl(
          params.url,
          params.method,
          JSON.stringify(params.data)
        );
        console.log('xxxxxxxxxxxxxxxxxx', isFromWebBrowser, params);
        return callRequest(params);
      };
    } else {
      return (params: any) => {
        return callRequest(params);
      };
    }
  }
}

// 打开新的Webview
export function openNewWebview(webviewPrams: IWebviewProps) {
  const { url, title, screentype = 0 } = webviewPrams;
  const origin_query = qString.parse(location.search);
  /** 跳转需永久保留的query参数 */
  const keep_query: Record<string, any> = pick(origin_query, ['from', 'slots']);

  const mergeUrl = mergeUrlQuery(url, { query: keep_query }, { reverse: true });
  const fullUrl = isUrl(mergeUrl) ? mergeUrl : location.origin + mergeUrl;

  return createBridgePromise('jsOpenNewWebview', {
    url: fullUrl,
    title: title,
    screentype: screentype,
  });
}
//刷新 Cookie 信息
export function refreshCookie() {
  if (isHasProductMethod('jsRefreshCookie')) {
    return createBridgePromise('jsRefreshCookie', {});
  } else {
    return new Promise((resolve, reject) => {
      resolve({
        code: 0, // [0:默认成功, -1:用户未登录],
        message: 'ok',
        data: {
          userId: '342343223', // 用户 Id
          name: 'string', // 用户昵称
          picture: 'string', // 用户头像地址
          email: '1222@qq.com', // 邮箱
          phone_number: '1768981887', // 手机号
        },
      });
    });
  }
}
//	清除右上角按钮
export function cleanRightButton() {
  return createBridgePromise('jsCleanRightButton', {});
}
//关闭H5页面
export function closePage() {
  if (Native.hasNativeMethod('jsClosePage')) {
    // if (dsBridge.hasNativeMethod('jsClosePage')) {
    return createBridgePromise('jsClosePage', {});
  }
  history.go(-1);
  return Promise.resolve();
}
//关闭所有 H5 页面
export function closeAllPage() {
  // if (dsBridge.hasNativeMethod('jsCloseAllPage')) {
  if (Native.hasNativeMethod('jsCloseAllPage')) {
    return createBridgePromise('jsCloseAllPage', {});
    // } else if (dsBridge.hasNativeMethod('jsClosePage')) {
  } else if (Native.hasNativeMethod('jsClosePage')) {
    return createBridgePromise('jsClosePage', {});
  } else {
    history.go(-1);
    return;
  }
}

export function registBackPressAction() {
  return createBridgePromise('jsRegistBackPressAction', {});
}
//返回键逻辑
export function registerBackPressed(callback: Function) {
  // 监听app返回事件，如果打开类型选择或者打开时间选择器，则阻止app返回事件
  // dsBridge.registerAsyn('onBackPressed', (responseCallback: Function) => {
  if (typeof Native.registerAsyn === 'function') {
    // registerAsyn有则用
    Native.registerAsyn('onBackPressed', (responseCallback: Function) => {
      callback &&
        typeof callback === 'function' &&
        typeof responseCallback === 'function' &&
        callback(responseCallback);
      typeof responseCallback === 'function' && responseCallback(false);
    });
  } else {
    Native.register('onBackPressed', (responseCallback: Function) => {
      callback &&
        typeof callback === 'function' &&
        typeof responseCallback === 'function' &&
        callback(responseCallback);
      typeof responseCallback === 'function' && responseCallback(false);
    });
  }
}
/** 4.22.0新版本客户端，直接由前端向服务端发送请求，取消jsGetUserInfo接口 */
// 获取用户信息
export function getUserInfo(from: string, forceLogin: boolean) {
  if (isHasProductMethod('jsGetUserInfo')) {
    const params = {
      from,
      forceLogin: forceLogin,
      forceAccountLogin: forceLogin,
    };
    console.log('================> jsGetUserInfo', params);
    return createBridgePromise('jsGetUserInfo', params);
  } else {
    const { USER_INFO } = getInfo();
    return Promise.resolve({
      code: 0, // [0:默认成功, -1:用户未登录],
      message: 'ok',
      data: USER_INFO,
    });
  }
}

// 获取加密结果
export function getSignBody(signParam: string) {
  if (isHasProductMethod('jsGetSign')) {
    return createBridgePromise('jsGetSign', { signParam });
  }
  return new Promise((resolve, reject) => {
    resolve({
      code: 0,
      message: 'ok',
      data: {
        signStr: '123456789',
      },
    });
  });
  /* if (process.env.NODE_ENV === 'production') {
    return createBridgePromise('jsGetSign', { signParam });
  } else {
    return new Promise((resolve, reject) => {
      resolve({
        code: 0,
        message: 'ok',
        data: {
          signStr: '123456789',
        },
      });
    });
  }*/
}

// 获取刷新页面的通知
export function notifyRefresh(callback: Function) {
  // dsBridge.register('jsNotifyRefresh', callback);
  Native.register('jsNotifyRefresh', callback);
}

export function hubReport(
  reportPlat: number,
  eventId: string,
  attr1: string,
  report_third_platform: boolean,
  data?: { [key: string]: any }
) {
  const reqData = {
    reportPlat: reportPlat, //上报平台 0-全平台 1-Hubble 2-Kibala
    hubbleEventId: eventId, // Hubble定义的EventId
    hubbleAttribute1: attr1, // Hubble定义的attribute1
    report_third_platform, // 是否上报第三方平台，如Google/facebook，取值为true/false，默认false
    hubbleExData: data, //其它参数(key-value对)
  };
  console.log('jsReport', reqData);

  // return createBridgePromise('jsReport', reqData);
  return fetchJsReportToApi(reqData);
}

export function ready(cb: any) {
  // if (dsBridge.hasNativeMethod('jsGetUserInfo')) {
  if (Native.hasNativeMethod('jsGetUserInfo')) {
    cb(null);
  } else if (window.opener) {
    bus.ready(cb);
  } else {
    cb(null);
  }
}

export function returnOpener(actionName?: string, data?: any, cb?: any) {
  // if (dsBridge.hasNativeMethod('jsGetUserInfo')) {
  if (Native.hasNativeMethod('jsGetUserInfo')) {
    //cb(null);
  } else if (window.opener) {
    bus.request(actionName || '', data, cb);
  } else {
    //
  }
}

export function callApp(event_name: string, data: object) {
  // if (dsBridge.hasNativeMethod('jsGamePostMessage')) {
  if (Native.hasNativeMethod('jsGamePostMessage')) {
    const reqData = {
      tp_event: event_name,
      href: location.href,
      data,
    };
    console.log('reqData', JSON.stringify(reqData, null, '\t'));
    createBridgePromise('jsGamePostMessage', reqData);
    setTimeout(() => {
      return Promise.resolve(true);
    }, 1000);
  }
  return Promise.resolve(true);
}

export function getRechargeSdkType() {
  /**
   0：不使用sdk；
1：使用bmartsdk；
2：使用其他sdk，待定；
   */
  const method = 'jsPayByBMart';
  // if (dsBridge.hasNativeMethod(method)) {
  if (Native.hasNativeMethod(method)) {
    return 1;
  }
  return 0;
}

/*
export function createBridgePromise(name: string, params: any) {
  return new Promise((resolve, reject) => {
    try {
      if (dsBridge.hasNativeMethod(name)) {
        console.log('enter', name);
        dsBridge.call(name, params, resolve);
      } else if (params && params.url) {
        window.location.href = params.url;
      }
    } catch (e) {
      reject(e);
    }
  });
}
*/
const getVisibilityChangeName = () => {
  if (typeof document.hidden !== 'undefined') {
    return { visibilityChange: 'visibilitychange', hidden: 'hidden' };
  }
  if (typeof document['webkitHidden'] !== 'undefined') {
    return {
      visibilityChange: 'webkitvisibilitychange',
      hidden: 'webkitHidden',
    };
  }
  return { visibilityChange: 'visibilitychange', hidden: 'hidden' };
};
export function payBySdk(data: any) {
  const { visibilityChange, hidden } = getVisibilityChangeName();
  return new Promise((resolve, reject) => {
    //const status = 0;
    let timer: any;
    const onChange = (e: any) => {
      if (!document[hidden]) {
        resolve(undefined);
        document.removeEventListener(visibilityChange, onDelayChange, false);
      }
    };
    const onDelayChange = (e: any) => {
      timer = setTimeout(() => {
        onChange(e);
      }, 1000);
    };
    document.addEventListener(visibilityChange, onDelayChange, false);
    const resolveFn = createBridgePromise('jsPayByBMart', data);
    Promise.resolve(resolveFn)
      .then((data) => {
        clearTimeout(timer);
        resolve(data);
      })
      .catch((err) => {
        clearTimeout(timer);
        reject(err);
      });
  });
}
