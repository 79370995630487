import BigNumber from 'bignumber.js';
/**
 * analyzing data types
 *
 * @param variable
 * @return {String} - data type.
 *
 * @example
 * type({}) // "object"
 * type([]) // "array"
 * type(5) // "number"
 * type(null) // "null"
 * type() // "undefined"
 * type(/abcd/) // "regex"
 * type(new Date()) // "date"
 */
const dataType = function (variable: any) {
  const str = Object.prototype.toString.call(variable);
  return (str as any).match(/\[object (.*?)\]/)[1].toLowerCase();
};
const randNumber = (m: number) => {
  return parseInt(Math.random() * Math.pow(10, m) + '');
};
/**
 * 自定义加法
 * @param num1
 * @param num2
 */
const addNum = (num1: number, num2: number) => {
  let sq1, sq2;
  try {
    sq1 = num1.toString().split('.')[1].length;
  } catch (e) {
    sq1 = 0;
  }
  try {
    sq2 = num2.toString().split('.')[1].length;
  } catch (e) {
    sq2 = 0;
  }
  const m = Math.pow(10, Math.max(sq1, sq2));
  return (num1 * m + num2 * m) / m;
};
const multipliedNum = (num1: number, num2: number) => {
  const x = new BigNumber(num1);
  const y = new BigNumber(num2);
  return Number(x.multipliedBy(y).toString()); // '3.14159292035398230088'
};

const divNumber = (num1: number, num2: number) => {
  const x = new BigNumber(num1);
  const y = new BigNumber(num2);
  return Number(x.div(y)); // '3.14159292035398230088'
};
export { dataType, randNumber, addNum, multipliedNum, divNumber };
