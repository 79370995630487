import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './app';
import { ready } from '@/utils/bridge';

import 'normalize.css/normalize.css';

localStorage.removeItem('browser-game-info');
ready((sessionInfo: any) => {
  console.log('readyed', sessionInfo);
  if (sessionInfo) {
    localStorage.setItem('browser-game-info', JSON.stringify(sessionInfo));
  } else {
    localStorage.removeItem('browser-game-info');
  }
  ReactDOM.render(<App />, document.getElementById('root'));
});

if ('production' === process.env.NODE_ENV && process.env.SW === 'SW') {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
