const getInfo = () => {
  const browserClientInfo = JSON.parse(
    localStorage.getItem('browser-game-info') || '{}'
  );

  /*
{
    "appInfo": {
        "Product-Id": "65",
        "Version-Code": 3000000,
        "Version-Name": "3.0.0",
        "Channel": "in_vb",
        "Device-Id": "HW_23F13102-2D76-3897-9EB8-936906DEC821"
    },
    "authInfo": {
        "auth": "96f214ea-2369-4ae3-a63c-550c68464384",
        "create_at": 1600948489,
        "expire_at": 1601553289,
        "signature_key": "857c3c2ec8cb60bc7598af594c9a50c1",
        "user": 10032219,
        "device": "HW_23F13102-2D76-3897-9EB8-936906DEC821",
        "app": 65,
        "ip": "156.249.25.195",
        "version": 3000000,
        "channel": "in_vb",
        "valid": 1
    },
    "accountInfo": {
        "isLogined": true,
        "isAccount": true,
        "isGuest": false,
        "userinfo": {
            "user_id": 10032219,
            "nickname": "谢福才",
            "avatar": "http://intune-dev-test-bucket.s3.ap-south-1.amazonaws.com/teenpatti_avatar/159729972_avatar?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3HEVIKTP2DI5IFFK%2F20200924%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20200924T114431Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=9cdde11b9657b0d402edf9fc2081372587aa4041831c41e45997e3f60d2f7787"
        }
    }
}
*/
  const appInfo = browserClientInfo.appInfo;
  const authInfo = browserClientInfo.authInfo;
  const accountInfo = browserClientInfo.accountInfo;
  const isFromWebBrowser = appInfo && authInfo && accountInfo && true;
  const user = (accountInfo && accountInfo.userinfo) || {};

  /*
{
    "userId": 10032219,
    "name": "谢福才",
    "picture": "http://intune-dev-test-bucket.s3.ap-south-1.amazonaws.com/teenpatti_avatar/159729972_avatar?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3HEVIKTP2DI5IFFK%2F20200924%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20200924T124206Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=af59e96429abe5913ca6d93100f62eaae2587a25338992dba9b915f8311166a1",
    "user_type": 1
}
*/
  const USER_INFO = isFromWebBrowser
    ? {
        userId: user.user_id,
        name: user.nickname,
        picture: user.avatar,
        user_type: accountInfo.isAccount,
      }
    : {
        userId: '342343223', // 用户 Id
        name: 'string', // 用户昵称
        picture: 'string', // 用户头像地址
        email: '1222@qq.com', // 邮箱
        phone_number: '1768981887', // 手机号
      };

  /*
{
    "origin": 1,
    "version": "3.0.0.000",
    "language": "en",
    "flavor": "in_vb",
    "versionCode": 3000000,
    "appVersion": "3.0.0.000",
    "systemVersion": "10",
    "productID": "65",
    "partnerID": "in_vb",
    "deviceID": "14ce2d4f810843b50194e1dfa89758dc",
    "tpDeviceID": "HW_FFFFFFFF-F480-0BF5-0000-000011ADA142"
}
*/
  const APP_INFO = isFromWebBrowser
    ? {
        origin: 'android',
        version: appInfo['Version-Name'],
        version_code: appInfo['Version-Code'],
        token: '',
        device_id: appInfo['Device-Id'],
        userId: (user.user_id || '').toString(),
        language:
          ['en-us', 'hi-in'].indexOf(navigator.language.toLowerCase()) >= 0
            ? navigator.userAgent
            : 'en-US',
        flavor: appInfo.Channel,

        productID: appInfo['Product-Id'],
      }
    : {
        origin: 1, /// int  Android 为1 iOS 为 2
        version: '0.0.1', /// string 获取App版本号（build号）
        token: 'dsfklhjhhh', /// string 鉴权 token
        device_id: '33dbc9e5db3430f8d00f5479eb684711', // 设备号
        userId: '12345678', /// stringy用户ID（8位数字）
        language: 'en-US', /// string 获取应用语言 zh-CN（中文）en-US（英文）hi-IN（印地语
        flavor: 'in_xiaomi1', //渠道

        versionCode: 3000000,
        appVersion: '3.0.0.000',
        systemVersion: '10',
        productID: '65',
        partnerID: 'in_vb',
        deviceID: '14ce2d4f810843b50194e1dfa89758dc',
        tpDeviceID: 'HW_FFFFFFFF-F480-0BF5-0000-000011ADA142',
      };
  const AUTH_INFO = { ...browserClientInfo.authInfo };
  console.log('USER_INFO', user, USER_INFO);
  return { isFromWebBrowser, APP_INFO, USER_INFO, AUTH_INFO };
}
export default getInfo;
