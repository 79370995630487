import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { closePage, getUserInfo, isHasBridgeMethod } from '@/utils/bridge';
import routers from '@/config/routerConfig';
import NotFound from '@/pages/404';
import RouteContext, { Fields } from '@/utils/context';
import { getAccountUserInfo } from '@/services/modules/user';
import { getURLQueryString } from '@/utils/network';
interface Props {
  appInfo: any;
}
const ctx = new Fields();
ctx.set('user', {});
ctx.set('app', {});

const initUserInfoByBridge = async () => {
  const user = await getUserInfo(getURLQueryString('from') || '', true)
    .then(async (rs: any) => {
      console.log('jsGetUserInfo', rs);
      if (rs.code === 0) {
        const user = rs.data || {};
        if (user.userId) {
          localStorage.setItem('USER_ID', user.userId);
          const key = `recharge-info-${user.userId}`;
          if (user.phone_number && user.email) {
            localStorage.setItem(
              `recharge-info-${user.userId}`,
              JSON.stringify({ phone: user.phone_number, email: user.email })
            );
            return Promise.resolve(user);
          }

          if (localStorage.getItem(key)) {
            const u = JSON.parse(localStorage.getItem(key) || 'null');
            if (u.phone && u.email) {
              return Promise.resolve({
                ...user,
                phone_number: u.phone,
                email: u.email,
              });
            }
          }
          const rs_1 = await getAccountUserInfo(user.userId);
          console.log('getAccountUserInfo', rs_1);
          if (rs_1.code === 0) {
            const u_1 = rs_1.data && rs_1.data.userinfo;
            if ((u_1 && u_1.phone) || u_1.email) {
              localStorage.setItem(
                `recharge-info-${user.userId}`,
                JSON.stringify({
                  phone: u_1.phone,
                  email: u_1.email,
                })
              );
              return Promise.resolve({
                ...user,
                phone_number: u_1.phone,
                email: u_1.email,
              });
            }
          }
          return await Promise.resolve(user);
        } else {
          return Promise.resolve(user);
        }
      } else {
        return Promise.resolve(null);
      }
    })
    .catch(() => {
      return Promise.resolve({ code: -1, data: null });
    });
  return user;
};

const initUserInfo = async (userId: number) => {
  try {
    /** 4.22.0新版本客户端，直接由前端向服务端发送请求，取消jsGetUserInfo接口 */
    if (isHasBridgeMethod('jsGetUserInfo')) {
      return initUserInfoByBridge();
    } else {
      const res = await getAccountUserInfo(userId);
      console.log('getAccountUserInfo', res);
      if (res.code === 0) {
        const user = res.data && res.data.userinfo;
        if ((user && user.phone) || user.email) {
          localStorage.setItem(
            `recharge-info-${userId}`,
            JSON.stringify({
              phone: user.phone,
              email: user.email,
            })
          );
        }
        return Promise.resolve({
          userId: user.user_id,
          name: user.nickname,
          picture: user.avatat,
          email: user.email,
          phone: user.phone_number,
          user_type: 1,
        });
      } else {
        throw new Error();
      }
    }
  } catch {
    return Promise.resolve({ code: -1, data: null });
  }
};

const AuthRoute = (route: any) => {
  const [logined, setLoginedInfo] = React.useState(-1);
  const version = '3.0.1'; //(route.appInfo && route.appInfo.version) || '';

  React.useEffect(() => {
    ctx.set('app', route.appInfo || {});
    console.log('route.appInfo: ', route.appInfo);
    if (route.needLogin === true) {
      if (!/^[01]\./.test(version)) {
        // 2.0以上版本的app不再支持游客登录
        initUserInfo(route.appInfo?.userId).then((user) => {
          ctx.set('user', user);
          if (user) {
            if (user.user_type > 0) {
              setLoginedInfo(1);
            } else if (user.user_type === 0) {
              closePage().then(() => {});
            } else {
              setLoginedInfo(1);
            }
          } else {
            closePage().then(() => {});
          }
        });
        return;
      } else {
        setLoginedInfo(1);
      }
    } else {
      setLoginedInfo(1);
    }
  }, [route.needLogin, route.appInfo, version]);

  if (route.needLogin !== true || logined > 0) {
    return (
      <Route
        key={`${route.path}/${logined}/Date.now()`}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    );
  } else {
    return (
      <div
        onClick={() => {
          location.reload();
        }}
        style={{
          fontSize: '0.38rem',
          textAlign: 'center',
          lineHeight: '100vh',
        }}
        key={`${route.path}/${logined}/Date.now()`}
      >
        {logined === -1 ? 'refresh' : 'You need login first!'}
      </div>
    );
  }
};

const Routes: React.FC<Props> = (props: Props): React.ReactElement => {
  return (
    <RouteContext.Provider value={ctx}>
      <Switch>
        {routers.map((route: any) => (
          <AuthRoute key={route.path} {...route} appInfo={props.appInfo} />
        ))}
        <Redirect from="/" to="/asset/detail" />
        <Route component={NotFound} />
      </Switch>
    </RouteContext.Provider>
  );
};

export default Routes;
