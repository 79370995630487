import axios, { ajax } from '@/helpers/axios';
import { setRequireParams, origin } from '@/utils/network';
const baseURLV1 = '/api/user/v1';
const baseURLV3 = '/api/user/v3';
export async function getAccountUserInfo(userId: number) {
  const options = setRequireParams({
    method: 'get',
    url: `${origin}${baseURLV3}/account/getuserinfo`,
    //data: params,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  return axios(options);
}

export async function uploadImage(params: any) {
  params.user_id = sessionStorage.getItem('USER_ID')
    ? Number(sessionStorage.getItem('USER_ID'))
    : undefined;
  params.server_name = 'teenpatti';
  return ajax(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURLV1}/file/uploadfile`,
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8',
      },
    })
  );
}

export async function getOthersUserInfo(params: number[]) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURLV3}/account/getotheruserinfo`,
      data: {
        user_id_list: params,
      },
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
