import { Dispatch } from 'redux';
import {
  getAssetInfo,
  getRechargeList,
  getTransactionList,
  getRechargeResult,
  guestLogin,
  requestRechargeAgentList,
  requestRespRechargeConf,
  jsReportToHubApi,
  jsReportToKibalaApi,
} from '@/services';
import {
  SAVE_ASSET_INFO,
  SAVE_ASSET_TRANSAcTIONS,
  RESET_TRANSAcTION_LIST,
  SAVE_RECHARGELIST_INFO,
  SAVE_RECHARGE_AGENT_LIST,
  SAVE_RECHARGE_CONF,
} from '@/constants/actionTypes';
import { GLOBAL_RESULT_STATUS } from '@/constants/enums/global';
export const fetchGuestLogin = (params: any) => async (dispatch: Dispatch) => {
  try {
    const requireParams = Object.assign({}, params.body);

    const response = await guestLogin(requireParams);
    if (response instanceof Error) {
      const error = JSON.parse(response.message);
      if (
        error.networkStatus === GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT ||
        error.networkStatus === GLOBAL_RESULT_STATUS.NETWORK_OUTAGE
      ) {
        params.callback &&
          params.callback(false, GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT);
        return;
      }
      if (error.networkStatus === GLOBAL_RESULT_STATUS.ERROR) {
        params.callback && params.callback(false, GLOBAL_RESULT_STATUS.ERROR);
        return;
      }
    }
    if (response && response.code === 0) {
      params.callback && params.callback(true);
    } else {
      // Toast.info(response.msg)
      params.callback &&
        params.callback(false, GLOBAL_RESULT_STATUS.ERROR, response.msg);
    }
  } catch (error) {
    console.log(error);
  }
};
export const fetchAssetInfo = (params: any) => async (dispatch: Dispatch) => {
  try {
    const requireParams = Object.assign({}, params.body);

    const response = await getAssetInfo(requireParams);
    if (response instanceof Error) {
      const error = JSON.parse(response.message);
      if (
        error.networkStatus === GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT ||
        error.networkStatus === GLOBAL_RESULT_STATUS.NETWORK_OUTAGE
      ) {
        params.callback &&
          params.callback(false, GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT);
        return;
      }
      if (error.networkStatus === GLOBAL_RESULT_STATUS.ERROR) {
        params.callback && params.callback(false, GLOBAL_RESULT_STATUS.ERROR);
        return;
      }
    }
    if (response && response.code === 0) {
      dispatch({
        type: SAVE_ASSET_INFO,
        payload: { data: response.data },
      });
      params.callback && params.callback(true);
    } else {
      // Toast.info(response.msg)
      params.callback &&
        params.callback(false, GLOBAL_RESULT_STATUS.ERROR, response.msg);
    }
  } catch (error) {
    console.log(error);
  }
};
/**
 * 查询充值套餐
 * @param params 公共参数
 */
export const fetchRechargeList =
  (params: any) => async (dispatch: Dispatch) => {
    try {
      const requireParams = Object.assign({}, params.body);

      const response = await getRechargeList(requireParams);
      if (response instanceof Error) {
        const error = JSON.parse(response.message);
        if (
          error.networkStatus === GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT ||
          error.networkStatus === GLOBAL_RESULT_STATUS.NETWORK_OUTAGE
        ) {
          params.callback &&
            params.callback(false, GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT);
          return;
        }
        if (error.networkStatus === GLOBAL_RESULT_STATUS.ERROR) {
          params.callback && params.callback(false, GLOBAL_RESULT_STATUS.ERROR);
          return;
        }
      }
      if (response && response.code === 0) {
        dispatch({
          type: SAVE_RECHARGELIST_INFO,
          payload: { data: response.data },
        });
        params.callback && params.callback(true, response.data);
      } else {
        // Toast.info(response.msg)
        params.callback &&
          params.callback(false, GLOBAL_RESULT_STATUS.ERROR, response.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const fetchTransactionList =
  (params: any) => async (dispatch: Dispatch) => {
    try {
      const requireParams = Object.assign({}, params.body);
      if (requireParams.page) {
        delete requireParams.page;
      }
      const response = await getTransactionList(requireParams);
      if (response instanceof Error) {
        const error = JSON.parse(response.message);
        if (
          error.networkStatus === GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT ||
          error.networkStatus === GLOBAL_RESULT_STATUS.NETWORK_OUTAGE
        ) {
          params.callback &&
            params.callback(false, GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT);
          return;
        }
        if (error.networkStatus === GLOBAL_RESULT_STATUS.ERROR) {
          params.callback && params.callback(false, GLOBAL_RESULT_STATUS.ERROR);
          return;
        }
      }
      if (response && response.code === 0) {
        dispatch({
          type: SAVE_ASSET_TRANSAcTIONS,
          payload: { data: response.data, page: params.body.page },
        });
        params.callback && params.callback(true);
      } else {
        // Toast.info(response.msg)
        params.callback &&
          params.callback(false, GLOBAL_RESULT_STATUS.ERROR, response.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const resetTransactionList = (params: any) => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_TRANSAcTION_LIST,
  });
  params.callback && params.callback(true);
};

export const fetchRechargeResult =
  (params: any) => async (dispatch: Dispatch) => {
    const requireParams = Object.assign({}, params.body);
    const response = await getRechargeResult(requireParams);
    params.callback && params.callback(response);
  };

export const fetchRechargeConf =
  (params: any) => async (dispatch: Dispatch) => {
    try {
      const requireParams = Object.assign({}, params.body);
      const response = await requestRespRechargeConf(requireParams);
      if (response && response.code === 0) {
        dispatch({
          type: SAVE_RECHARGE_CONF,
          payload: { data: response.data },
        });
        params.callback && params.callback(true, response.data);
      } else {
        params.callback &&
          params.callback(false, GLOBAL_RESULT_STATUS.ERROR, response.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

/**
 * 查询支付列表
 * @param params 公共参数
 */
export const fetchAgentList = (params: any) => async (dispatch: Dispatch) => {
  try {
    const requireParams = Object.assign({}, params.body);
    const response = await requestRechargeAgentList(requireParams);
    if (response instanceof Error) {
      dispatch({
        type: SAVE_RECHARGE_AGENT_LIST,
        payload: {
          data: {
            agents: [],
            default_selected: 0,
          },
        },
      });
      const error = JSON.parse(response.message);
      if (
        error.networkStatus === GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT ||
        error.networkStatus === GLOBAL_RESULT_STATUS.NETWORK_OUTAGE
      ) {
        params.callback &&
          params.callback(false, GLOBAL_RESULT_STATUS.NETWORK_TIMEOUT);
        return;
      }
      if (error.networkStatus === GLOBAL_RESULT_STATUS.ERROR) {
        params.callback && params.callback(false, GLOBAL_RESULT_STATUS.ERROR);
        return;
      }
    }
    if (response && response.code === 0) {
      dispatch({
        type: SAVE_RECHARGE_AGENT_LIST,
        payload: { data: response.data },
      });
      params.callback && params.callback(true, response.data);
    } else {
      dispatch({
        type: SAVE_RECHARGE_AGENT_LIST,
        payload: {
          data: {
            agents: [],
            default_selected: 0,
          },
        },
      });
      params.callback &&
        params.callback(false, GLOBAL_RESULT_STATUS.ERROR, response.msg);
    }
  } catch (error) {
    console.log(error);
  }
};
// 哈勃上报接口
export const jsReportToHub = async (params: any) => {
  try {
    const requireParams = Object.assign({}, params.body);
    const response = await jsReportToHubApi(requireParams);
    if (response && response.code === 0) {
      params.callback && params.callback(true, response.data || response);
    } else {
      params.callback &&
        params.callback(false, GLOBAL_RESULT_STATUS.ERROR, response.msg);
    }
  } catch (error) {
    console.log(error);
  }
};
// Kibala上报接口
export const jsReportToKibala = async (params: any) => {
  try {
    const requireParams = Object.assign({}, params.body);
    const response = await jsReportToKibalaApi(requireParams);
    if (response && response.ret === 0) {
      params.callback && params.callback(true, response.data);
    } else {
      params.callback &&
        params.callback(false, GLOBAL_RESULT_STATUS.ERROR, response.msg);
    }
  } catch (error) {
    console.log(error);
  }
};
