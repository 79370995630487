enum GLOBAL_RESULT_STATUS {
  SUCCESS = 0,
  ERROR = 1,
  NETWORK_OUTAGE = 2,
  NETWORK_TIMEOUT = 3,
}

enum ORDER_TYPE {
  RECHARGE = 2,
  WITHDRAW = 4,
  REFUND = 5,
  SYSTM_AWARD = 6,
  GAMELOSE = 7,
  GAMEWIN = 8,
  BONUS = 12,
  TRANSFER = 13, // 币商转账
}

enum REHCARGE_STATUS {
  PEDDING = 0,
  SUCCESS = 1,
  FAILED = 2,
}

enum WITHDRAW_STATUS {
  PEDDING = 0, //审核中
  SUCCESS = 1, //已到账
  BACK = 2, //已驳回
  TRANSFER_IN = 4, //转账中
  CANCEL = 5, //已取消
  CONFIRM = 6, //待确认
  FROZEN = 7, //已冻结
}

enum ASSETS_TYPE {
  RUPEE = 1,
  OTHER = 2,
  VIRTUAL_CURRENCY = 3,
  SLOTS = 6,
}

enum WITHDRAW_RESULT_DETAIL {
  PEDDING = 0,
  SUCCESS = 1,
  FAILED = 2,
}

enum RECHARGE_CHANNEL {
  PAYTM = 1,
  PHONEPE = 2,
  CREDITCARD = 3,
  UPI = 4,
  DEBITCARD = 5,
  WALLET = 6,
  ID_WALLET = 20,
  ID_NTB = 21,
  ID_CREDITCARD = 22,
}
enum PICKER_TYPE {
  ALL = 0,
  CANCEL = -1,
}
export {
  GLOBAL_RESULT_STATUS,
  PICKER_TYPE,
  ORDER_TYPE,
  ASSETS_TYPE,
  REHCARGE_STATUS,
  WITHDRAW_STATUS,
  WITHDRAW_RESULT_DETAIL,
  RECHARGE_CHANNEL,
};
