import {
  SAVE_ASSET_INFO,
  SAVE_ASSET_TRANSAcTIONS,
  RESET_TRANSAcTION_LIST,
  SAVE_RECHARGELIST_INFO,
  SAVE_RECHARGE_AGENT_LIST,
  SAVE_RECHARGE_CONF,
} from '@/constants/actionTypes';
import { AssetState } from '@/models';
import { ASSETS_TYPE } from '@/constants/enums/global';
import { isInSlots } from '@/utils/util';

const initialState: AssetState = {
  asset_info: {},
  transaction_info: {
    amtList: {
      total: 0,
      items: [],
    },
  },
  recharge_agent_ist: {
    agents: [],
    default_selected: 0,
  },
  rechargeConf: {
    use_user_lib: false,
    invalid_emails: [],
    invalid_words: [],
  },
  rechargeList: {
    id: 0,
    activity_type: 0,
    bonus_tips: '',
    max_buy_times: 0,
    expire_time: 0,
    min_amount: 0,
    max_amount: 0,
    remain_second: 0,
    expire_msg: '',
    tabs: [],
    show_channel: false,
    data_report: {},
  },
  paytm_id: '',
  bonus_tips: {},
  withdraw_times: 0,
};

const AssetReducer = (
  state: AssetState = initialState,
  action: any
): AssetState => {
  switch (action.type) {
    case SAVE_RECHARGELIST_INFO:
      return {
        ...state,
        rechargeList: {
          id: action.payload.data.id,
          activity_type: action.payload.data.activity_type,
          bonus_tips: action.payload.data.bonus_tips,
          max_buy_times: action.payload.data.max_buy_times,
          expire_time: action.payload.data.expire_time,
          remain_second: action.payload.data.remain_second,
          expire_msg: action.payload.data.expire_msg,
          tabs: action.payload.data.tabs,
          min_amount: action.payload.data.min_amount / 100,
          max_amount: action.payload.data.max_amount / 100,
          show_channel: action.payload.data.show_channel || false,
          data_report: action.payload.data.data_report,
        },
      };
    case SAVE_ASSET_INFO:
      const {
        payload: { data },
      } = action;

      const is_slots = isInSlots();
      const item = data.items.find((item: any) => {
        return is_slots
          ? item.assets_type === ASSETS_TYPE.SLOTS
          : item.assets_type === ASSETS_TYPE.RUPEE;
      });

      item.amount = item.amount / 100;
      item.deposited = item.deposited / 100;
      item.withdraw_amt = item.withdraw_amt / 100;

      return {
        ...state,
        asset_info: item,
        paytm_id: data.paytm_id,
        bonus_tips: data.bonus_tips,
        withdraw_times: data.withdraw_times,
      };
    case SAVE_ASSET_TRANSAcTIONS: {
      const { data } = action.payload;

      if (action.payload.page === 0) {
        return {
          ...state,
          transaction_info: {
            amtList: {
              total: data.total,
              items: [...(data.items || [])],
            },
          },
        };
      }
      return {
        ...state,
        transaction_info: {
          amtList: {
            total: data.total,
            items: [
              ...state.transaction_info.amtList.items,
              ...(data.items || []),
            ],
          },
        },
      };
    }
    case RESET_TRANSAcTION_LIST:
      return {
        ...state,
        transaction_info: {
          amtList: {
            total: 0,
            items: [],
          },
        },
      };
    case SAVE_RECHARGE_AGENT_LIST:
      return {
        ...state,
        recharge_agent_ist: action.payload.data,
      };
    case SAVE_RECHARGE_CONF:
      return {
        ...state,
        rechargeConf: action.payload.data,
      };
    default:
      return state;
  }
};

export default AssetReducer;
