import { getQueryVariable, isInApp } from '@/utils/util';

!(function () {
  const jsbRecordCallMap = new Map();
  let supportMethodsList = [];

  callHandler('jsGetSupportMethodList', '', (val) => {
    supportMethodsList = val?.method_list || [];
  });
  function callHandler(funname, funData, fun, resId = undefined) {
    // 兼容两个参数的 callNative(name, callback)
    // resId不为undefined表示客户端主动调了我们register函数时传递的唯一id，用于客户端到时候要处理的事情
    if (typeof funData == 'function') {
      fun = funData;
      funData = {};
    }
    if (!window.JsBridge) {
      return;
    }

    const jsbKey = getQueryVariable('bridge_name') || 'callNativeMethod';
    const funId = funname + '_' + String(performance.now());
    if (typeof fun === 'function') {
      jsbRecordCallMap.set(funId, fun);
    }
    window.JsBridge[jsbKey](funname, resId || funId, JSON.stringify(funData));
  }

  function register(name, handler) {
    jsbRecordCallMap.set(name, handler);
    if (!window.JsBridge) {
      return;
    }
    const jsbKey = getQueryVariable('bridge_name') || 'callNativeMethod';
    window.JsBridge[jsbKey]('jsRegister', '', name);
  }

  function unregister(name, handler) {
    jsbRecordCallMap.delete(name);
    if (!window.JsBridge) {
      return;
    }
    const jsbKey = getQueryVariable('bridge_name') || 'callNativeMethod';
    window.JsBridge[jsbKey]('jsUnregister', '', name);
    handler && handler();
  }

  function hasNativeMethod(name) {
    if (!isInApp()) {
      return false;
    }
    if (supportMethodsList.length) {
      return supportMethodsList.includes(name);
    } else {
      return [
        'jsGetSupportMethodList',
        'jsShare',
        'jsGetAppInfo',
        'jsOpenBrowser',
        'jsOpenNewWebview',
        'jsGamePostMessage',
        'jsClosePage',
        'jsCloseAllPage',
        'jsShowToast',
        'jsSetTitle',
        'jsRegister',
        'jsUnregister',
        'xlThirdLogin',
        'jsReturnValue',
      ].includes(name);
    }
  }

  /* 提供给native调用 */
  function onNativeCallback(name, callUUID, callbackParamsData) {
    // 执行回调 `callbackParamsData`回调函数的参数 可有可无
    // 通过ID获取对应的回调函数,ID为纯数字表示注册事件，客户端通知h5前端该执行该name事件的回调
    let responseData;
    const cbStr = callbackParamsData || '{}';
    try {
      responseData = JSON.parse(cbStr);
    } catch (e) {
      responseData = JSON.parse(
        cbStr.replace(/\n/g, '\\n').replace(/\t/g, '\\t').replace(/\r/g, '\\r')
      );
    }
    console.log('jsbReturn', name, callUUID, responseData, jsbRecordCallMap);
    if (!isNaN(Number(callUUID))) {
      const fun = jsbRecordCallMap.get(name);
      try {
        fun && fun(responseData);
        if (typeof responseData !== 'function') {
          callHandler('jsReturnValue', { ret: false }, undefined, callUUID);
        }
        // 注册事件执行完毕后不需要释放资源
        // jsbRecordCallMap.delete(name);
      } catch (exception) {
        console.warn(
          'JSBridge: WARNING: register javascript handler threw.',
          responseData,
          exception
        );
      }
    } else {
      const fun = jsbRecordCallMap.get(callUUID);
      try {
        fun && fun(responseData);
        // 执行完毕后释放资源
        jsbRecordCallMap.delete(callUUID);
      } catch (exception) {
        console.warn(
          'JSBridge: WARNING: javascript handler threw.',
          responseData,
          exception
        );
      }
    }
  }

  window.WebJsBridge = {
    call: callHandler,
    hasNativeMethod: hasNativeMethod,
    register: register,
    unregister: unregister,
  };
  window.onNativeCallback = onNativeCallback;
})();
