const SAVE_HELP_CATEGORIES = 'SAVE_HELP_CATEGORIES';
const SAVE_HELP_TEMPVALUES = 'SAVE_HELP_TEMPVALUES';
const SAVE_HELP_QUESTIONS = 'SAVE_HELP_QUESTIONS';
const SAVE_HELP_CATEGORIEQUESTIONS = 'SAVE_HELP_CATEGORIEQUESTIONS';
const SAVE_HELP_QUESTIONDETAIL = 'SAVE_HELP_QUESTIONDETAIL';
const SAVE_HELP_FEEDBACKLIST = 'SAVE_HELP_FEEDBACKLIST';
const SAVE_HELP_FEEDBACKDETAIL = 'SAVE_HELP_FEEDBACKDETAIL';
const CLEAR_HELP_TEMPVALUES = 'CLEAR_HELP_TEMPVALUES';
const DELETE_HELP_PICTURES = 'DELETE_HELP_PICTURES';
const SAVE_HELP_COMMON_FB = 'SAVE_HELP_COMMON_FB';
export {
  SAVE_HELP_CATEGORIES,
  SAVE_HELP_TEMPVALUES,
  SAVE_HELP_QUESTIONS,
  SAVE_HELP_CATEGORIEQUESTIONS,
  SAVE_HELP_QUESTIONDETAIL,
  SAVE_HELP_FEEDBACKLIST,
  SAVE_HELP_FEEDBACKDETAIL,
  CLEAR_HELP_TEMPVALUES,
  DELETE_HELP_PICTURES,
  SAVE_HELP_COMMON_FB,
};
