import { IRequireParams } from '@/constants/interfaces/global';
import { isNull } from 'util';
const getCookie = (cookie_name: string) => {
  const allcookies = document.cookie;
  let cookie_pos = allcookies.indexOf(cookie_name); //索引的长度
  let value = '';
  if (cookie_pos != -1) {
    cookie_pos += cookie_name.length + 1;
    let cookie_end = allcookies.indexOf(';', cookie_pos);

    if (cookie_end == -1) {
      cookie_end = allcookies.length;
    }

    value = unescape(allcookies.substring(cookie_pos, cookie_end));
  }
  return value;
};
export function setCookie(name: string, value: string, time: number) {
  let cookie = `${name}=${escape(value)};domain=;path=/`;
  if (time) {
    const exp = new Date();
    exp.setTime(exp.getTime() + time);
    cookie = `${cookie};expires=${exp.toUTCString()}`;
  }

  document.cookie = cookie;
}
/**
 * url search 名称查询
 * @param name searchName
 */
const getURLQueryString = (name: string) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
};
// {
//   method: 'POST',
//   url: `${baseURL}/feedback/getfblistbytype`,
//   data: params,
//   headers:{
//       'Content-Type':'application/json; charset=utf-8'
//   }
// }
const setRequireParams = (params: IRequireParams) => {
  const is_notoken =
    process.env.TEENPATTI_ENV === 'dev' || process.env.TEENPATTI_ENV === 'test';
  const newPrams: any = {};
  newPrams.url = `${params.url}${is_notoken ? '?notoken=1' : ''}`;
  if (!isNull(params.data)) {
    newPrams.data = params.data;
  }

  if (typeof params.method === 'undefined') {
    newPrams.method = 'POST';
  } else {
    newPrams.method = params.method;
  }
  if (isNull(params.headers)) {
    newPrams.headers = {
      'Content-Type': 'application/json; charset=utf-8',
    };
  } else {
    newPrams.headers = params.headers;
  }
  return newPrams;
};

/**
 * 匹配url中的search
 * @param {c} searchName
 * @param {*} urlStr
 */
const getParamsUrlStr = (name: string, urlStr: string) => {
  const url = urlStr.substr(urlStr.indexOf('?'), urlStr.length);
  const reg = new RegExp(`[\\?&]${name}=([^&]*)`);
  const v = url.match(reg);
  return v === null ? '' : v[1];
};

const onLine = () => {
  //基于promise，可以用async和await
  const img = new Image();
  img.src =
    window.location.protocol +
    '//' +
    window.location.host +
    '/ping.jpg?_t=' +
    Date.now();
  return new Promise((resolve, reject) => {
    img.onload = function () {
      resolve(true);
    };
    img.onerror = function () {
      resolve(false);
    };
  });
};

/** ADD_NEW_AREA */
const origin = (function (v) {
  // 新孟加拉语
  if (/^test-h5-app\.teenpatticoin/.test(v)) {
    return 'http://test-api-rummy.teenpatticoin.com';
  }
  if (/^tp-h5-app\.teenpatticoin/.test(v)) {
    return `${location.protocol}//api-rummy.teenpatticoin.com`;
  }

  // 孟加拉语
  if (/^test-h5-app\.teenpattyboss/.test(v)) {
    return 'http://test-api.teenpattyboss.com';
  }
  if (/^tp-h5-app\.teenpattyboss/.test(v)) {
    return `${location.protocol}//api.teenpattyboss.com`;
  }
  // 巴西
  if (/^test-h5-app\.trucorelax/.test(v)) {
    return 'http://test-api.trucorelax.com';
  }
  if (/^tp-h5-app\.trucorelax/.test(v)) {
    return `${location.protocol}//api.trucorelax.com`;
  }
  // 印度
  if (/^test-h5-app/.test(v)) {
    return 'http://test-api-rummy.teenpattiboss.com'; //test-api.teenpattiboss.com
  }
  if (/^tp-h5-app/.test(v)) {
    return `${location.protocol}//api-rummy.teenpattiboss.com`; //api-tp.teenpattiboss.com
  }
  // 印地语已废弃
  // if (/^yn-tp-h5-test/.test(v)) {
  //   return `${location.protocol}//test-idn-api.teenpattiboss.com`;
  // }
  // if (/^yn-tp-h5-preview/.test(v) || /^yn-tp-h5\./.test(v)) {
  //   return `${location.protocol}//idn-api.teenpattiboss.com`;
  // }
  return '';
  // return 'http://test-api-rummy.teenpatticoin.com';
})(location.hostname);

export {
  origin,
  getCookie,
  getURLQueryString,
  setRequireParams,
  getParamsUrlStr,
  onLine,
};
