import { multiToOne } from '@/utils/object';

import common from '../locales/bn/common.json';
import asset from '../locales/bn/asset.json';
import withdraw from '../locales/bn/withdraw.json';
import recharge from '../locales/bn/recharge.json';
import gpRecharge from '../locales/bn/gp_recharge.json';
import help from '../locales/bn/help.json';
import component from '../locales/bn/component.json';
import agentTransfer from '../locales/bn/agent-transfer.json';
import pay from '../locales/en/pay.json';

const obj = {
  page: {
    common,
    component,
    withdraw,
    recharge,
    gpRecharge,
    help,
    asset,
    agentTransfer,
    pay,
  },
};

export type BnLangType = NestedKeyOf<typeof obj>;

export default { messages: multiToOne(obj), locale: 'bn' };
