import axios, { ajax } from '@/helpers/axios';
import { setRequireParams, origin } from '@/utils/network';
const baseURL = '/api/user/v1';
const baseURLV3 = '/api/user/v3';
export async function guestLogin(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/account/guestlogin`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
export async function getAssetInfo(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/assetinfo`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
/**
 * 查询充值套餐
 * @param params 公共参数
 */
export async function getRechargeList(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURLV3}/amt/rechargelist`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
export async function getTransactionList(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}/web-server${baseURL}/amt/trade_list`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

export async function getRechargeResult(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURL}/amt/recharge/detail`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}

/**
 * 获取充值信息
 */
export async function getOfferList() {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}/web-server${baseURL}/game/getoffer`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
export async function requestRechargeAgentList(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURLV3}/amt/recharge_agent/list`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
export async function requestRespRechargeConf(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}${baseURLV3}/amt/recharge_conf`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
export async function jsReportToHubApi(params: any) {
  return axios(
    setRequireParams({
      method: 'POST',
      url: `${origin}/event_report/api/event/push`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
export async function jsReportToKibalaApi(params: any) {
  return ajax(
    setRequireParams({
      method: 'POST',
      url: `${location.protocol}//etl.teenpattiboss.com/api/event_report/v1/vidhunter/event/report`,
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
  );
}
