import {
  SAVE_WITHDRAW_LIST,
  RESET_WITHDRAW_LIST,
  SAVE_WITHDRAW_WAIT_AUDIT_LIST,
  GET_BIND_LIST,
  //  BIND_ACCOUNT,
} from '@/constants/actionTypes';
import { WithdrawState, WithDrawAccountState } from '@/models';

const initialState: WithdrawState = {
  amtList: {
    total: 0,
    items: [],
  },
  amtWaitAuditList: {
    total: 0,
    items: [],
  },
};

const WithdrawReducer = (
  state: WithdrawState = initialState,
  action: any
): WithdrawState => {
  switch (action.type) {
    case SAVE_WITHDRAW_LIST:
      const { data: amtListData } = action.payload;
      if (action.payload.page === 0) {
        return {
          ...state,
          amtList: {
            total: amtListData.total,
            items: [...(amtListData.items || [])],
          },
        };
      }
      return {
        ...state,
        amtList: {
          total: amtListData.total,
          items: [...state.amtList.items, ...(amtListData.items || [])],
        },
      };
    case SAVE_WITHDRAW_WAIT_AUDIT_LIST:
      const { data: amtWaitAuditListData } = action.payload;
      return {
        ...state,
        amtWaitAuditList: {
          total: amtWaitAuditListData.total,
          items: [],
        },
      };
    case RESET_WITHDRAW_LIST:
      return {
        ...state,
        amtList: {
          total: 0,
          items: [],
        },
      };

    default:
      return state;
  }
};

const withdrawAccountReducer = (
  state: WithDrawAccountState = { items: [] },
  action: any
): any => {
  switch (action.type) {
    case GET_BIND_LIST:
      const { data } = action.payload;
      return {
        ...state,
        items: data && data.items,
      };
    default:
      return state;
  }
};
export default WithdrawReducer;
export { withdrawAccountReducer };
