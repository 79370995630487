import React from 'react';
class Fields {
  values: { [key: string]: any } = {};
  set(name: string, value: any) {
    this.values[name] = value;
  }

  get(name: string) {
    return this.values[name];
  }

  getAll() {
    return this.values;
  }
}
const RouteContext = React.createContext({} as Fields);
export default RouteContext;
export { Fields };
